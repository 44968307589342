import React from "react"
import { useEffect, useRef } from "react"
import confetti from "canvas-confetti"

// Styles
import "./_start-a-project.scss"

// Components
import WrapButton from "../Buttons/wrapButton"

// Animations
import { successParAnim, successBtnAnim, createSuccessTitle } from "../../animations/pages/sapAnim"
import { motion } from "framer-motion"
import { $desktop } from "../../utils/breakpoints"
import { Container, Section } from "../StyledComponents/layoutComponentStyles"

// Interfaces
interface SapSuccessProps {}

// Confetti
export const randomInRange = (min: number, max: number) => {
  return Math.random() * (max - min) + min
}

const confettiVars = {
  zIndex: 99999,
  angleRight: 135,
  angleLeft: 45,
  spread: () => randomInRange(75, 110),
  particleCount: () => randomInRange(100, 200),
  originXRight: () => randomInRange(0.9, 0.95),
  originXLeft: () => randomInRange(0, 0.075),
  originY: () => randomInRange(0.6, 0.8),
  colors: ["#ffffff", "#ffc582", "#ffaebb"],
}

/*









*/

const SapSuccess: React.FunctionComponent<SapSuccessProps> = () => {
  // Refs
  const containerRef = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLHeadingElement>(null)
  const parRef = useRef<HTMLParagraphElement>(null)
  const btnRef = useRef<HTMLDivElement>(null)

  // Helper functions
  const confettiMultiples = () => {
    setTimeout(() => {
      // console.log("confetti 1")
      return confetti?.({
        angle: confettiVars.angleRight,
        spread: confettiVars.spread(),
        particleCount: confettiVars.particleCount(),
        origin: { x: confettiVars.originXRight(), y: confettiVars.originY() },
        colors: confettiVars.colors,
        zIndex: confettiVars.zIndex,
      })
    }, 500)
    setTimeout(() => {
      // console.log("confetti 2")
      return confetti({
        angle: confettiVars.angleLeft,
        spread: confettiVars.spread(),
        particleCount: confettiVars.particleCount(),
        origin: { x: confettiVars.originXLeft(), y: confettiVars.originY() },
        colors: confettiVars.colors,
        zIndex: confettiVars.zIndex,
      })
    }, 1500)
    setTimeout(() => {
      // console.log("confetti 3")
      return confetti({
        angle: confettiVars.angleRight,
        spread: confettiVars.spread(),
        particleCount: confettiVars.particleCount(),
        origin: { x: confettiVars.originXRight(), y: confettiVars.originY() },
        colors: confettiVars.colors,
        zIndex: confettiVars.zIndex,
      })
    }, 2500)
    setTimeout(() => {
      // console.log("confetti 4")
      return confetti({
        angle: confettiVars.angleLeft,
        spread: confettiVars.spread(),
        particleCount: confettiVars.particleCount(),
        origin: { x: confettiVars.originXLeft(), y: confettiVars.originY() },
        colors: confettiVars.colors,
        zIndex: confettiVars.zIndex,
      })
    }, 3500)
    setTimeout(() => {
      // console.log("confetti 5")
      return confetti({
        angle: confettiVars.angleRight,
        spread: confettiVars.spread(),
        particleCount: confettiVars.particleCount(),
        origin: { x: confettiVars.originXRight(), y: confettiVars.originY() },
        colors: confettiVars.colors,
        zIndex: confettiVars.zIndex,
      })
    }, 4500)
  }

  // Effects
  useEffect(() => {
    containerRef.current?.classList.add("is-visible")
    confettiMultiples()
    createSuccessTitle(titleRef.current)
    successParAnim(parRef.current)
    successBtnAnim(btnRef.current)
  }, [])

  return (
    <motion.div initial={{ opacity: 1 }} animate={{ opacity: 1 }} exit={{ opacity: 0, transition: { duration: 0.3, ease: "linear" } }}>
      <Section fullHeight alignCenter>
        <Container fourteenClm>
          <div className='c-success' ref={containerRef}>
            <h2 ref={titleRef}>The start of something magical...</h2>
            <p className={$desktop ? "o-par-lrg" : undefined} ref={parRef}>
              And just like that, your request is winging its way up to Silk Studio HQ. We're truly flattered that you want to work
              with us and we'll be sure to respond you as soon as possible. In the meantime, why not take a look at some of our recent
              work?
            </p>
            <div ref={btnRef}>
              <WrapButton text={"Work"} link={"/work/"} style={{ transform: "translate3d(-1rem, 0, 0)" }} />
            </div>
          </div>
        </Container>
      </Section>
    </motion.div>
  )
}

export default SapSuccess
