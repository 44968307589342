import * as React from "react"
import { useEffect, useContext, useState } from "react"

import CursorContext from "../../context/cursorContext"

// Styles
import "./_cookie-consent.scss"

// Components

// Animations
import { AnimatePresence, motion } from "framer-motion"
import { Container, FlexRow } from "../StyledComponents/layoutComponentStyles"
import { easeOutExpo } from "../../animations/animUtils"
import { $tablet } from "../../utils/breakpoints"

// Variables
const cookieConsentVars = {
  initial: {},
  animate: {},
  exit: {
    y: "-125%",
    transition: {
      duration: 0.6,
      ease: easeOutExpo,
    },
  },
}

interface CookieConsentProps {}

/*









*/

const CookieConsent: React.FunctionComponent<CookieConsentProps> = () => {
  // Context
  const { setCursorState } = useContext(CursorContext)

  // States
  const [bannerVisibility, setBannerVisibilty] = useState<boolean>(false)

  // Helpers
  const storageType = localStorage
  const consentPropertyName = "ss_consent"
  const cookie = storageType.getItem(consentPropertyName)
  const saveToStorage = () => storageType.setItem(consentPropertyName, "true")

  useEffect(() => {
    if (!cookie) {
      setBannerVisibilty(true)
      document.getElementById("header")?.classList.add("u-consent")
    }
  }, [cookie])

  return (
    <AnimatePresence initial={false}>
      {bannerVisibility && (
        <motion.div initial='initial' animate='animate' exit='exit' variants={cookieConsentVars} className='o-consent'>
          <Container sixteenClm fullHeight>
            <FlexRow style={{ margin: "0 auto" }}>
              <p className={!$tablet ? "o-par-sml" : undefined}>
                By continuing we assume your permission to deploy cookies, as detailed in our{" "}
                <a
                  href='https://www.silkstudio.co/terms'
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ textDecoration: "underline" }}
                  onMouseEnter={() => setCursorState?.("is-link")}
                  onMouseLeave={() => setCursorState?.("")}
                >
                  privacy policy
                </a>
                .{" "}
                <a
                  href=''
                  style={{ textDecoration: "underline" }}
                  onMouseEnter={() => setCursorState?.("is-link")}
                  onMouseLeave={() => setCursorState?.("")}
                  onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                    e.preventDefault()
                    saveToStorage()
                    setBannerVisibilty(false)
                    document.getElementById("header")?.classList.remove("u-consent")
                  }}
                >
                  Close
                </a>
              </p>
            </FlexRow>
          </Container>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default CookieConsent
