import React from "react"

interface ScrollSafeProps {
  scrollSafe?: boolean
  setScrollSafe?: React.Dispatch<React.SetStateAction<boolean>>
}

const ScrollSafeContext = React.createContext<ScrollSafeProps>({})

export const ScrollSafeProvider = ScrollSafeContext.Provider

export default ScrollSafeContext
