import * as React from "react"
import { useContext } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
// import { useLocation } from "@reach/router"

// Styles
import "./_header.scss"

// Components
import { Container, FlexRow } from "../StyledComponents/layoutComponentStyles"
import MagneticMouse from "../MagneticMouse"
import NavContext from "../../context/navContext"

// Animations
import { motion } from "framer-motion"
import { easeOutExpo } from "../../animations/animUtils"
import { $desktop } from "../../utils/breakpoints"

// Interfaces
interface HeaderProps {
  setSapState?: React.Dispatch<React.SetStateAction<string>>
  landingExitAnim: () => void
}

interface HeaderInnerProps {
  siteTitle?: String
  landingExitAnim: () => void
}

/* 









*/

const Header: React.FunctionComponent<HeaderProps> = ({ landingExitAnim }) => {
  // Get site info
  const siteData = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        siteAuthor
        siteDescription
        siteTitle
        workAvailability
      }
    }
  `)
  const data = siteData.sanitySiteSettings
  const session = window.sessionStorage.getItem("newSession")
  // const location = useLocation()

  const checkPage = () => {
    // if (location.pathname === "/" && session === null) {
    //   return 3.5
    // } else {
    return 1
  }

  if (session === null) {
    return (
      <motion.header
        initial={{ y: "-9rem" }}
        animate={{
          y: $desktop ? "3rem" : "0rem",
          transition: { duration: 1.5, delay: checkPage(), ease: easeOutExpo },
        }}
        className='o-header'
        id='header'
      >
        <HeaderInner siteTitle={data.siteTitle} landingExitAnim={landingExitAnim} />
      </motion.header>
    )
  } else {
    return (
      <header className='o-header' id='header'>
        <HeaderInner siteTitle={data.siteTitle} landingExitAnim={landingExitAnim} />
      </header>
    )
  }
}

export default Header

export const HeaderInner: React.FunctionComponent<HeaderInnerProps> = ({ landingExitAnim }) => {
  const { navState, setNavState } = useContext(NavContext)

  return (
    <Container sixteenClm id='header-inner'>
      <FlexRow justifySpaceBetween id='header-flex'>
        <FlexRow alignBaseline>
          <Link
            to='/'
            style={{
              textDecoration: "none",
              color: "inherit",
              marginRight: "1rem",
            }}
            onClick={() => {
              if (navState) setNavState?.(false)
            }}
          >
            <MagneticMouse>
              <div className='o-site-logo' onClick={() => landingExitAnim()} role='button' tabIndex={0} onKeyDown={() => null}>
                <svg viewBox='0 0 58 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M9.67336 25.3947C14.6866 25.3947 18.3935 22.2598 18.3935 17.5924C18.3935 14.144 16.6636 12.3676 11.4033 10.3473C7.76694 8.95407 6.70781 8.08327 6.70781 6.69C6.70781 5.36638 7.90815 4.39109 9.42623 4.39109C10.9443 4.39109 12.2859 5.33155 13.0626 7.14281L17.1932 4.60008C15.6398 1.56971 12.9567 0.0371094 9.56745 0.0371094C4.87199 0.0371094 1.72991 2.82366 1.72991 6.79449C1.72991 10.417 3.60103 12.2979 8.79076 14.353C12.2153 15.7114 13.4156 16.6519 13.4156 18.0452C13.4156 19.9958 11.8975 21.0407 9.74397 21.0407C7.09615 21.0407 5.22503 19.543 4.9426 16.6867L0 17.9059C0.741389 22.434 4.66016 25.3947 9.67336 25.3947Z'
                    fill={"rgba(250, 250, 250, 1)"}
                  />
                  <path
                    d='M21.1403 4.91357H26.0829V0.629252H21.1403V4.91357ZM21.211 24.9071H26.0123V7.73495H21.211V24.9071Z'
                    fill='rgba(250, 250, 250, 1)'
                  />
                  <path
                    d='M34.5813 25.2902C35.3933 25.2902 36.3819 25.1161 37.4057 24.7329V21.0059C36.9467 21.1104 36.5231 21.18 36.17 21.18C35.4639 21.18 35.0403 20.9711 35.0403 20.1699V0.629252H30.2742V20.6576C30.2742 23.8621 32.1453 25.2902 34.5813 25.2902Z'
                    fill='rgba(250, 250, 250, 1)'
                  />
                  <path
                    d='M40.3119 24.9071H45.1133V19.8913L47.3728 17.3485L52.1742 24.9071H57.8581L50.762 14.2833L57.1874 7.73495H50.7973L45.1133 14.0395V0.629252H40.3119V24.9071Z'
                    fill='rgba(250, 250, 250, 1)'
                  />
                </svg>
              </div>
            </MagneticMouse>
          </Link>
          <div className='o-site-tagline'>a digital design studio.</div>
        </FlexRow>
        <FlexRow id='nav_icon_container'>
          <MagneticMouse>
            <div className='o-nav-icon' role='button' tabIndex={0} onClick={() => setNavState?.(!navState)} onKeyDown={() => null}>
              <span className={navState ? "o-nav-icon__line-1 is-active" : "o-nav-icon__line-1"}></span>
              <span className={navState ? "o-nav-icon__line-2 is-active" : "o-nav-icon__line-2"}></span>
            </div>
          </MagneticMouse>
        </FlexRow>
      </FlexRow>
    </Container>
  )
}
