import React from "react"

interface NewsletterContextProps {
  newsletterState?: boolean
  setNewsletterState?: React.Dispatch<React.SetStateAction<boolean>>
}

const NewsletterContext = React.createContext<NewsletterContextProps>({})

export const NewsletterProvider = NewsletterContext.Provider

export default NewsletterContext
