import { $desktop } from "../utils/breakpoints"
import { scrollData, smoothScrolling } from "../utils/smoothScroll"
import { ScrollTrigger } from "gsap/ScrollTrigger"

/**
 * A series of helper functions, methods and variables used in the layout component
 *
 *  [1]. Session handler
 *  [2]. Zoom handler
 *  [3]. Scroll restoration handler
 *  [4]. Smooth scroll initialiser
 *  [5]. Add zoomHandler event listener
 *  [6]. Remove zoomHandler event listener
 *
 *
 */

/*
 
 
 
 
 
 
 
 
 
 */

// [1] Session Handler

/**
 * Determines whether the user is coming from a new or existing session.
 */

export const handleSession = () => {
  if (!window.sessionStorage.getItem("newSession")) {
    // console.log(`session storage is ${window.sessionStorage.getItem("newSession")}, updating to true...`)
    setTimeout(() => {
      window.sessionStorage.setItem("newSession", "true")
      // console.log(`session storage is ${window.sessionStorage.getItem("newSession")}`)
    }, 4500)
  }
}

// [2]. Zoom handler

/**
 * Reinstates zoom functionality removed by the vw rem size.
 * @param { KeyboardEvent } event - KeyboardEvent
 */

export const handleZoom = (e: KeyboardEvent) => {
  const rem = (window.innerWidth / 100) * 0.83
  if (e.metaKey === true && e.key === "=") {
    let windowSize = window.outerWidth / window.innerWidth
    document.documentElement.style.fontSize = `${rem * windowSize}px`
    document.body.style.fontSize = `${rem * windowSize}px`
  }
}

// [3]. Scroll restoration handler

/**
 * Checks the window API for history.scrollResoration object and if present sets it to manual.
 */

export const handleScrollRestoration = () => {
  if (window.history.scrollRestoration) {
    window.history.scrollRestoration = "manual"
  }
}

// [4]. Smooth scroll initialiser

/**
 * Initialises smooth scrolling functionality.
 */

export const initSmoothScroll = () => {
  const scrollContainer = document.getElementById("scroll-container")

  if (scrollContainer !== null && $desktop) {
    ScrollTrigger.scrollerProxy(".scroll-container", {
      scrollTop(value) {
        if (arguments.length && value) {
          scrollData.rounded = value // setter
        }
        return scrollData.rounded // getter
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        }
      },
    })

    requestAnimationFrame(() => smoothScrolling())
  }
}

// [5]. Add zoomHandler event listener

/**
 * Adds a "keydown" event listener to the document object with the handleZoom function
 */

export const addZoomHandler = () => {
  document.addEventListener("keydown", e => {
    handleZoom(e)
  })
}

// [6]. Remove zoomHandler event listener

/**
 * Adds a "keydown" event listener to the document object with the handleZoom function
 */

export const removeZoomHandler = () => {
  document.removeEventListener("keydown", e => {
    handleZoom(e)
  })
}
