import * as React from "react"
import { useContext } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import CursorContext from "../../context/cursorContext"

// Styled Components
export const StyledPillBtn = styled.div`
position: relative;
width: 18rem;
height: 5.75rem;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
flex-grow: 0;
flex-shrink: 0;
text-align: center;
background-color: ${props => props.theme.textColor};
outline: none;
outline-color: transparent;
outline-width: 0px;
border-color: transparent;
border: none;
border-radius: 3rem;
border-width: none;
border-style: none;
border-image-width: 0;
padding: 0 1.25rem;
cursor: pointer;
transition: all 0.9s cubic-bezier(0.16, 1, 0.3, 1);
overflow: hidden !important;

@media screen and (max-width: 1024px) {
  width: 28.5rem;
  height: 9.5rem;
  border-radius: 8rem;
  padding: 0 1.25rem 0 1.25rem;
  -webkit-mask-image: -webkit-radial-gradient(white, black);

}

@media screen and (max-width: 480px) {
  width: 55rem;
  height: 16.5rem;
  border-radius: 25rem;
  padding: 0 2.5rem 0 2.5rem;
  -webkit-mask-image: -webkit-radial-gradient(white, black);

}


&::before {
  content: "";
  z-index: 1;
  width: 100%;
  height: 18.5rem;
  position: absolute;
  top: -6rem;
  left: 0;
  right: 0;
  transform-origin: center;
  margin: 0 auto;
  border-radius: 100%;
  z-index: 2;
  transition: all 1s 0.05s cubic-bezier(0.16, 1, 0.3, 1);
   background-color: ${props => (props.theme.type === "dark" ? "#000" : "#fff")};
  transform: translate3d(0, 18.5rem, 0);

  @media screen and (max-width: 1024px) {
    height: 28.5rem;
    top: auto;
    transform: translate3d(0, 28.5rem, 0);
  }

  @media screen and (max-width: 480px) {
    height: 55rem;
    transform: translate3d(0, 56rem, 0);
  }

}

&:hover {
  &::before { 
    width: 100%;
    height: 18.5rem;
    transform: translate3d(0, 0, 0);

    @media screen and (max-width: 1024px) {
      height: 27.5rem;
    }

    @media screen and (max-width: 480px) {
      height: 55rem;
    }

  }

  .o-par-lrg {
    &:nth-of-type(1) {
      transform: translate3d(0, -300%, 0) skewY(-7.5deg);
    }

    &:nth-of-type(2) {
      font-weight: 600;
      transform: translate3d(0, -50%, 0) skewY(0deg);
       text-color: ${props => props.theme.textColor};
    }
  }
}

&.is-active {
  &::before {
    width: 100%;
    height: 18.5rem;
    transform: translate3d(0, 0, 0);

    @media screen and (max-width: 1024px) {
      height: 27.5rem;
    }

    @media screen and (max-width: 1024px) {
      height: 55rem;
    }

  } 
}

.o-par-lrg {
  opacity: 1;
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  z-index: 2;
  transform: translate3d(0, -50%, 0) skewY(0deg);
  transition: all 0.9s 0.05s cubic-bezier(0.16, 1, 0.3, 1);

  @media screen and (max-width: 480px) {
    font-size: 4.3653rem; 
  }

  &:nth-of-type(2) {
    transform: translate3d(0, 250%, 0) skewY(-7.5deg);
  }
}

.radio-btn {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.radio-btn:checked ~ .o-par-lrg:nth-of-type(1) {
  transform: translate3d(0, -300%, 0) skewY(-7.5deg);
}

.radio-btn:checked ~ .o-par-lrg:nth-of-type(2) {
  font-weight: 600;
  transform: translate3d(0, -50%, 0) skewY(0deg);
 text-color: ${props => props.theme.textColor};
  }
}
`

export const BtnWrapper = styled.div`
border-radius: 3rem,
overflow: hidden,

@media screen and (max-width: 1024px) {
  border-radius: 8rem;
}

@media screen and (max-width: 480px) {
  border-radius: 18.5rem;
}

`

// Interfaces
interface LinkPillButtonProps {
  text: string
  link: string
  replace?: boolean | undefined
}

interface ActionPillButtonProps {
  text: string
  clickAction: ((event: React.MouseEvent<HTMLElement>) => void) | undefined
}

interface SapPillButtonProps {
  step: number
  stepForward: (() => void) | undefined
  id: string
  value: string
  name: string
  handleChange?: any
}

/*









*/

export const LinkPillButton: React.FunctionComponent<LinkPillButtonProps> = ({ text, link, replace }) => {
  const { setCursorState } = useContext(CursorContext)

  return (
    <Link
      to={link}
      replace={replace}
      style={{
        textDecoration: "none",
        color: "inherit",
      }}
      onMouseEnter={() => setCursorState?.("is-link")}
      onMouseLeave={() => setCursorState?.("")}
      onMouseUp={() => setCursorState?.("")}
    >
      <BtnWrapper>
        <StyledPillBtn>
          <div className='pill-hover'></div>
          <p className='o-par-lrg'>{text}</p>
          <p className='o-par-lrg'>{text}</p>
        </StyledPillBtn>
      </BtnWrapper>
    </Link>
  )
}

export const ActionPillButton: React.FunctionComponent<ActionPillButtonProps> = ({ text, clickAction }) => {
  const { setCursorState } = useContext(CursorContext)

  return (
    <BtnWrapper>
      <StyledPillBtn
        onClick={clickAction}
        onMouseEnter={() => setCursorState?.("is-link")}
        onMouseLeave={() => setCursorState?.("")}
        onMouseUp={() => setCursorState?.("")}
      >
        <div className='pill-hover'></div>
        <p className='o-par-lrg'>{text}</p>
        <p className='o-par-lrg'>{text}</p>
      </StyledPillBtn>
    </BtnWrapper>
  )
}

export const SapPillButton: React.FunctionComponent<SapPillButtonProps> = ({ step, stepForward, id, value, name, handleChange }) => {
  // Context
  const { setCursorState } = useContext(CursorContext)

  // Helper functions
  const checkType = () => {
    const arr: Array<HTMLInputElement> = Array.from(document.querySelectorAll(".radio-btn"))
    arr.forEach(el => {
      if (el?.checked === true) {
        el.parentElement?.classList.add("is-active")
      } else {
        el.parentElement?.classList.remove("is-active")
      }
    })
  }

  return (
    <div className={`pill-btn step-${step}`}>
      <StyledPillBtn
        onClick={() => {
          checkType()
          stepForward?.()
        }}
        onMouseEnter={() => setCursorState?.("is-link")}
        onMouseLeave={() => setCursorState?.("")}
        onMouseUp={() => setCursorState?.("")}
      >
        <input type='radio' id={id} className='radio-btn' value={value} name={name} onChange={handleChange} />
        <div className='pill-hover'></div>
        <p className='o-par-lrg'>{value}</p>
        <p className='o-par-lrg'>{value}</p>
      </StyledPillBtn>
    </div>
  )
}
