export const scrollData = {
  ease: 0.1,
  current: 0,
  previous: 0,
  rounded: 0,
}

export const smoothScrolling = (): void => {
  scrollData.current = window.scrollY
  scrollData.previous += (scrollData.current - scrollData.previous) * scrollData.ease
  scrollData.rounded = Math.round(scrollData.previous * 100) / 100

  const container = document.getElementById("scroll-container")

  if (container) {
    container.style.transform = `matrix(1, 0, 0, 1, 0, -${scrollData.rounded})`
  }

  requestAnimationFrame(() => smoothScrolling())
}

//
// Mutation obsderver (not currently in use)

const callback = (mutations: any): void => {
  mutations.forEach((mutation: any) => {
    if (mutation.attributeName === "style") {
      console.log(mutation.target.style.transform)
    }
  })
}

export const observer = new MutationObserver(callback)
