import { easeOutExpo, easeInOutExpo, easeInExpo, $speedP1, $speedP2 } from "./animUtils"

export const fadeInOut = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      duration: $speedP1,
      ease: "linear",
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: $speedP1,
      ease: "linear",
    },
  },
}

export const genericFadeUp = {
  initial: { opacity: 0, y: "50px" },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.9,
      ease: easeOutExpo,
      delay: 0.6,
    },
  },
  exit: {
    opacity: 0,
    y: "50px",
    transition: {
      duration: 0.9,
      ease: easeInExpo,
    },
  },
}

export const scrollPrompt = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      duration: $speedP1,
      ease: "linear",
      delay: 0.9,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: $speedP1,
      ease: "linear",
    },
  },
}

export const slideTitleParent = {
  initial: {},
  animate: { transition: { staggerChildren: 0.125, delayChildren: 0.3 } },
  exit: { transition: { staggerChildren: 0.125 } },
}

export const slideTitleChild = {
  initial: {
    y: "100%",
  },

  animate: {
    y: "0%",
    transition: {
      duration: 0.9,
      ease: easeOutExpo,
    },
  },
  exit: {
    y: "100%",
    transition: { duration: $speedP2, ease: easeInOutExpo },
  },
}

export const verticalTagline = {
  initial: {
    x: "-150%",
  },

  animate: {
    x: "0%",
    transition: {
      duration: $speedP2,
      ease: easeOutExpo,
      delay: 0.9,
    },
  },
  exit: {
    x: "-150%",
    transition: { duration: $speedP2, ease: easeInOutExpo },
  },
}

export const delayedFade = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      duration: $speedP1,
      ease: "linear",
      delay: 1.6,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: $speedP1,
      ease: "linear",
    },
  },
}

export const delayedslideTitleParent = {
  initial: {},
  animate: { transition: { staggerChildren: 0.2, delayChildren: 1 } },
  exit: { transition: { staggerChildren: 0.125 } },
}

export const delayverticalTagline = {
  initial: {
    x: "-150%",
  },

  animate: {
    x: "0%",
    transition: {
      duration: $speedP2,
      ease: easeOutExpo,
      delay: 1.5,
    },
  },
  exit: {
    x: "-150%",
    transition: { duration: $speedP2, ease: easeInOutExpo },
  },
}

export const greyBackground = {
  inital: { backgroundColor: "rgba(219, 219, 219, 0)" },
  animate: {
    backgroundColor: "rgba(219, 219, 219, 1)",
    transition: { duration: $speedP1, ease: "linear", delay: 0.3 },
  },

  exit: {
    backgroundColor: "rgba(219, 219, 219, 0)",
    transition: { duration: $speedP1, ease: "linear", delay: 0.6 },
  },
}

export const pageTransitionInstant = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      duration: $speedP1,
      ease: "linear",
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: $speedP1,
      ease: "linear",
    },
  },
}

export const pageTransitionContact = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: { duration: $speedP1, ease: "linear", delay: 0.6 },
  },
  exit: { opacity: 0, transition: { duration: $speedP1, ease: "linear" } },
}
