import { DefaultTheme } from "styled-components"

import xsArrowDark from "../assets/icons/arrows/arrow_x-small--dark.svg"
import xsArrowLight from "../assets/icons/arrows/arrow_x-small--light.svg"

// Theme

export const darkTheme: DefaultTheme = {
  type: "dark",
  backgroundColor: "rgba(22, 22, 22, 1)",
  textColor: "rgba(250, 250, 250, 1)",
  xsArrow: xsArrowDark,
}

export const lightTheme: DefaultTheme = {
  type: "light",
  backgroundColor: "rgba(250, 250, 250, 1)",
  textColor: "rgba(22, 22, 22, 1)",
  xsArrow: xsArrowLight,
}
