import { gsap } from "gsap"
import { SplitText } from "gsap/SplitText"

type SingleElementFunction = (el: any) => void

type TriggeredSingleElementFunction = (el: any, trigger: any) => void

type DelayedSingleElementFunction = (el: any, delay: number) => void

// Reveal title
export const slideTitleAnim: SingleElementFunction = el => {
  return gsap.from(el, {
    y: "200%",
    skewY: "10%",
    duration: 0.9,
    ease: "expo.out",
    stagger: 0.2,
  })
}

export const createRevealTitle: SingleElementFunction = el => {
  if (document.fonts) {
    document.fonts.ready.then(() => {
      const splitInner = new SplitText(el, {
        type: "lines",
      })
      new SplitText(el, {
        type: "words, lines",
        linesClass: "u-overflow-reveal",
      })
      return slideTitleAnim(splitInner.lines)
    })
  } else {
    setTimeout(() => {
      const splitInner = new SplitText(el, {
        type: " lines",
      })
      new SplitText(el, {
        type: "words, lines",
        linesClass: "u-overflow-reveal",
      })
      return slideTitleAnim(splitInner.lines)
    }, 200)
  }
}

// Delayed reveal title
export const delayedSlideTitleAnim: SingleElementFunction = el => {
  return gsap.from(el, {
    y: "200%",
    skewY: "10%",
    duration: 0.9,
    ease: "expo.out",
    stagger: 0.2,
    delay: 1.5,
  })
}

export const createDelayedRevealTitle: SingleElementFunction = el => {
  if (document.fonts) {
    document.fonts.ready.then(() => {
      const splitInner = new SplitText(el, {
        type: "lines",
      })
      new SplitText(el, {
        type: "words, lines",
        linesClass: "u-overflow-reveal",
      })
      return delayedSlideTitleAnim(splitInner.lines)
    })
  } else {
    setTimeout(() => {
      const splitInner = new SplitText(el, {
        type: "lines",
      })
      new SplitText(el, {
        type: "words, lines",
        linesClass: "u-overflow-reveal",
      })
      return delayedSlideTitleAnim(splitInner.lines)
    }, 200)
  }
}

// Triggered reveal title
export const triggerSlideTitleAnim: TriggeredSingleElementFunction = (el, trigger) => {
  return gsap.from(el, {
    scrollTrigger: {
      trigger: trigger,
      start: "0px 62.5%",
    },
    y: "200%",
    skewY: "10%",
    duration: 0.6,
    ease: "expo.out",
    stagger: 0.2,
  })
}

export const createTriggerRevealTitle: SingleElementFunction = el => {
  if (document.fonts) {
    document.fonts.ready.then(() => {
      const splitInner = new SplitText(el, {
        type: "lines",
      })
      new SplitText(el, {
        type: "words, lines",
        linesClass: "u-overflow-reveal",
      })
      return triggerSlideTitleAnim(splitInner.lines, el)
    })
  } else {
    setTimeout(() => {
      const splitInner = new SplitText(el, {
        type: "lines",
      })
      new SplitText(el, {
        type: "words, lines",
        linesClass: "u-overflow-reveal",
      })
      return triggerSlideTitleAnim(splitInner.lines, el)
    }, 200)
  }
}

// Slide text
export const createSlideText: DelayedSingleElementFunction = (el, delay) => {
  if (document.fonts) {
    document.fonts.ready.then(() => {
      const splitInner = new SplitText(el, {
        type: "words, lines",
      })
      return gsap.from(splitInner.lines, {
        y: "100%",
        opacity: 0,
        duration: 0.6,
        ease: "expo.out",
        stagger: 0.125,
        delay: delay,
      })
    })
  } else {
    setTimeout(() => {
      const splitInner = new SplitText(el, {
        type: "words, lines",
      })
      return gsap.from(splitInner.lines, {
        y: "100%",
        opacity: 0,
        duration: 0.6,
        ease: "expo.out",
        stagger: 0.125,
        delay: delay,
      })
    }, 200)
  }
}

export const createTriggerSlideText: DelayedSingleElementFunction = (el, delay) => {
  if (document.fonts) {
    document.fonts.ready.then(() => {
      const splitInner = new SplitText(el, {
        type: "words, lines",
      })
      return gsap.from(splitInner.lines, {
        scrollTrigger: {
          trigger: el,
          start: "0px 75%",
        },
        y: "100%",
        opacity: 0,
        duration: 0.6,
        ease: "expo.out",
        stagger: 0.125,
        delay: delay,
      })
    })
  } else {
    setTimeout(() => {
      const splitInner = new SplitText(el, {
        type: "lines",
      })
      return gsap.from(splitInner.lines, {
        scrollTrigger: {
          trigger: el,
          start: "0px 75%",
        },
        y: "100%",
        opacity: 0,
        duration: 0.6,
        ease: "expo.out",
        stagger: 0.125,
        delay: delay,
      })
    }, 200)
  }
}

export const triggerFadeIn: DelayedSingleElementFunction = (el, delay) => {
  return gsap.from(el, {
    scrollTrigger: {
      trigger: el,
      start: "0px 75%",
    },
    opacity: 0,
    duration: 0.3,
    ease: "linear",
    delay: delay,
  })
}
