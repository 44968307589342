import * as React from "react"
import { useContext } from "react"
import CursorContext from "../../context/cursorContext"

// Styles
import "./_magnetic-mouse.scss"

// Components

// Animations

interface MagneticMouseProps {
  children: React.ReactNode
}

/*









*/

const MagneticMouse: React.FunctionComponent<MagneticMouseProps> = ({ children }) => {
  const { setCursorState } = useContext(CursorContext)

  return (
    <div
      className='o-mag-mouse'
      role='button'
      tabIndex={-1}
      onMouseMove={e => {
        if (window.innerWidth > 1024) {
          const elem = e.currentTarget
          const position = elem.getBoundingClientRect()
          const xCenter = position.x + position.width / 2
          const xPos = (e.clientX - xCenter) / 2
          const yCenter = position.y + position.height / 2
          const yPos = (e.clientY - yCenter) / 2
          const children = elem.children as HTMLCollectionOf<HTMLElement>

          children[0].style.transform = `translate3d(${xPos}px,${yPos}px,0px)`
        }
      }}
      onMouseOut={e => {
        const children = e.currentTarget.children as HTMLCollectionOf<HTMLElement>
        children[0].style.transform = `translate3d(0px,0px,0px)`
      }}
      onMouseEnter={() => setCursorState?.("is-link")}
      onMouseLeave={() => setCursorState?.("")}
      onFocus={() => null}
      onBlur={() => null}
    >
      {children}
    </div>
  )
}

export default MagneticMouse
