import * as React from "react"
import Layout from "./src/components/layout"
import { AnimatePresence } from "framer-motion"

export const wrapPageElement = ({ element, props }) => {
  return (
    <Layout {...props}>
      <AnimatePresence
        exitBeforeEnter
        onExitComplete={() => {
          // console.log("Exit animation complete...")
          window.scrollTo(0, 0)
        }}
      >
        {element}
      </AnimatePresence>
    </Layout>
  )
}

export const shouldUpdateScroll = ({ routerProps, prevRouterProps }) => {
  if (prevRouterProps !== undefined) {
    if (prevRouterProps.location.pathname === routerProps.location.pathname) {
      return false
    } else {
      document.querySelector(".o-footer").classList.add("is-hidden")
    }
  }

  return false
}

export const onClientEntry = () => {
  console.log(
    `%c${String.fromCodePoint(0x1f918)} Made with ${String.fromCodePoint(0x2764)} by Silk Studio`,

    "color:white; background-color:#1484FF; border-radius:15px; padding:7.5px 14px"
  )
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  setTimeout(() => {
    document.querySelector(".o-footer").classList.remove("is-hidden")
  }, 1800)
}

/*





















*/
// if (prevLocation === null) {
//   gsap.set(".transition-flag", { y: "0%" })
//   setTimeout(() => {
//     gsap.fromTo(
//       ".transition-flag",
//       {
//         y: "0%",
//       },
//       { y: "-100%", duration: 0.9, ease: "expo.inOut" }
//     )
//   }, 500)
// } else {
//   setTimeout(() => {
//     document.querySelector(".o-footer").style.opacity = "1"
//     gsap.fromTo(
//       ".transition-flag",
//       {
//         y: "0%",
//       },
//       { y: "-100%", duration: 0.9, ease: "expo.inOut" }
//     )
//   }, transitionDelay - 600)
// }
