import { Variants } from "framer-motion"
import { gsap } from "gsap"
import { SplitText } from "gsap/SplitText"
import { easeOutExpo, easeInExpo, $speedP2, $speedP1 } from "../animUtils"

type ContactFormAnim = (form: GSAPTweenTarget, btn: GSAPTweenTarget) => void

export const sapHeroTitleAnim = (el: any): gsap.core.Tween =>
  gsap.from(el, {
    y: "200%",
    skewY: "10%",
    duration: 0.9,
    ease: "expo.out",
    delay: 0.6,
    stagger: 0.2,
  })

export const sapHeroTitleExitAnim = (el: any): gsap.core.Tween =>
  gsap.to(el, {
    y: "200%",
    skewY: "10%",
    duration: 0.6,
    ease: "expo.in",
    stagger: 0.15,
  })

export const sapTitleAnimDelayed = (el: any): gsap.core.Tween => {
  return gsap.from(el, {
    y: "200%",
    skewY: "10%",
    duration: 0.9,
    ease: "expo.out",
    stagger: 0.2,
    delay: 0.3,
  })
}

export const sapBtnAnim = (el: any): gsap.core.Tween => {
  return gsap.from(el, {
    y: "100%",
    opacity: 0,
    duration: 0.6,
    ease: "expo.out",
    stagger: 0.1,
    delay: 0.6,
  })
}

export const sapFadeAnim = (el: any): gsap.core.Tween => {
  return gsap.from(el, {
    opacity: 0,
    duration: 0.6,
    ease: "linear",
    delay: 0.6,
  })
}

export const landingBGAnim = (el: any): gsap.core.Tween => {
  return gsap.to(el, {
    opacity: 0,
    duration: 0.05,
    ease: "expo.out",
  })
}

export const sapSectionExitAnim = (): void => {
  const arr: Array<Element> = Array.from(document.querySelectorAll(".sap-title__inner") as NodeListOf<HTMLElement>)
  const sapStart = document.getElementById("sap-landing")

  if (arr.length) {
    sapHeroTitleExitAnim(arr)
  }

  if (sapStart !== null) {
    setTimeout(() => {
      landingBGAnim(sapStart)
    }, 500)
  }
}

export const sapContactFormAnim: ContactFormAnim = (form, btn) => {
  return gsap
    .timeline()
    .from(form, { opacity: 0, duration: 0.6, ease: "linear", delay: 0.3 })
    .from(btn, { opacity: 0, y: "100%", duration: 0.6, ease: "expo.out" }, "-=0.3")
}

export const stepTitleAnimDelayed = (el: any): void => {
  if (document.fonts) {
    document.fonts.ready.then(() => {
      const splitInner = new SplitText(el, {
        type: "lines",
      })
      new SplitText(el, {
        type: "chars, lines",
        linesClass: "u-overflow-reveal",
      })
      return sapTitleAnimDelayed(splitInner.lines)
    })
  } else {
    setTimeout(() => {
      const splitInner = new SplitText(el, {
        type: "lines",
      })
      new SplitText(el, {
        type: "chars, lines",
        linesClass: "u-overflow-reveal",
      })
      return sapTitleAnimDelayed(splitInner.lines)
    }, 200)
  }
}

export const successTitle = (el: any): gsap.core.Tween => {
  return gsap.from(el, {
    y: "200%",
    skewY: "10%",
    duration: 0.9,
    ease: "expo.out",
    stagger: 0.2,
    delay: 0.6,
  })
}

export const createSuccessTitle = (el: any): void => {
  if (document.fonts) {
    document.fonts.ready.then(() => {
      const splitInner = new SplitText(el, {
        type: "lines",
      })
      new SplitText(el, {
        type: " lines",
        linesClass: "u-overflow-reveal",
      })
      return successTitle(splitInner.lines)
    })
  } else {
    setTimeout(() => {
      const splitInner = new SplitText(el, {
        type: "lines",
      })
      new SplitText(el, {
        type: " lines",
        linesClass: "u-overflow-reveal",
      })
      return successTitle(splitInner.lines)
    }, 200)
  }
}

export const successParAnim = (el: any): void => {
  if (document.fonts) {
    document.fonts.ready.then(() => {
      const splitInner = new SplitText(el, {
        type: "lines",
      })
      return gsap.from(splitInner.lines, {
        y: "100%",
        opacity: 0,
        duration: 0.6,
        ease: "expo.out",
        stagger: 0.1,
        delay: 0.9,
      })
    })
  } else {
    setTimeout(() => {
      const splitInner = new SplitText(el, {
        type: "lines",
      })
      return gsap.from(splitInner.lines, {
        y: "100%",
        opacity: 0,
        duration: 0.6,
        ease: "expo.out",
        stagger: 0.1,
        delay: 0.9,
      })
    }, 200)
  }
}

export const successBtnAnim = (el: any): gsap.core.Tween => {
  return gsap.from(el, {
    opacity: 0,
    y: "100%",
    duration: 0.6,
    ease: "expo.out",
    delay: 1.2,
  })
}

// Variants
export const fieldVars: Variants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: { duration: 0.1, ease: "linear", delay: 0 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 1.5 * $speedP1, ease: "linear" },
  },
}

export const btnParentVars: Variants = {
  initial: {},
  animate: {
    transition: {
      staggerChildren: 0.1,
      delayChildren: 1.05,
    },
  },
  exit: {
    transition: {
      delayChildren: 0.1,
      staggerChildren: 0.1,
    },
  },
}

export const sapBtnChildVars: Variants = {
  initial: { opacity: 0, y: "65%" },
  animate: {
    opacity: 1,
    y: "0%",
    transition: { duration: $speedP2, ease: easeOutExpo },
  },
  exit: {
    opacity: 0,
    y: "100%",
    transition: { duration: $speedP2, ease: easeInExpo },
  },
}

export const btnChildVars: Variants = {
  initial: { opacity: 0, y: "65%" },
  animate: {
    opacity: 1,
    y: "0%",
    transition: { duration: $speedP2, ease: easeOutExpo },
  },
  exit: {
    opacity: 0,
    transition: { duration: $speedP1, ease: "easeOut" },
  },
}

export const successVars: Variants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: { duration: $speedP1, ease: "linear", delay: 0.9 },
  },
  exit: {
    opacity: 0,
    transition: { duration: $speedP1, ease: "linear" },
  },
}
