import React from "react"

interface SapButtonSafeContextProps {
  sapButtonSafe?: boolean
  setSapButtonSafe?: React.Dispatch<React.SetStateAction<boolean>>
}

const SapButtonSafeContext = React.createContext<SapButtonSafeContextProps>({})

export const SapButtonSafeProvider = SapButtonSafeContext.Provider

export default SapButtonSafeContext
