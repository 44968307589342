import React from "react"

interface CursorContextProps {
  cursorState?: string
  setCursorState?: React.Dispatch<React.SetStateAction<string>>
}

const CursorContext = React.createContext<CursorContextProps>({})

export const CursorProvider = CursorContext.Provider

export default CursorContext
