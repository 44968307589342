/**
 * A series of helper functions, methods and variables used in the contact components
 *
 *  [1]. Encode handler
 *  [2]. Focus handler
 *  [3]. Blur handler
 *  [4]. Form fields interface
 *
 *
 */

/*
 
 
 
 
 
 
 
 
 
 */

// [1]. Encode handler

/**
 * Encoding function used in the handle submit method of forms to structure the data.
 *
 * @param {object} data - An object passed to the function containing data with names as keys and attributes as values.
 */
export const encode = (data: any) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

// [2]. Focus handler

/**
 * Handles what happens when a form field is focused upon.
 *
 * @param {React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>} event - Accessible event object with various methods and functions.
 */

export const handleFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  const label = Array.from(event!.currentTarget!.parentElement!.children).find(elem => elem.tagName === "LABEL")
  label?.classList.add("is-active")
}

// [3]. Blur handler

/**
 * Handles what happens when a form field is focused off.
 *
 * @param {React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>} event - Accessible event object with various methods and functions.
 */

export const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  if (event.currentTarget.value) {
    return
  } else {
    const label = Array.from(event?.currentTarget?.parentElement!.children).find(elem => elem.tagName === "LABEL")
    label?.classList.remove("is-active")
  }
}

// [4]. Form fields interface

/**
 * Props for the form fields component
 */

export interface FormFieldsProps {
  /**
   *  Function to run when the input value is changed.
   *
   * @param {React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>} event - Accessible event object with various methods and functions.
   */
  handleChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  /**
   *  Function to run when the input field is focused.
   *
   * @param {React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>} event - Accessible event object with various methods and functions.
   */
  handleFocus: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  /**
   *  Function to run when the input field is blurred.
   *
   * @param {React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>} event - Accessible event object with various methods and functions.
   */
  handleBlur: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  /**
   *  State of the form submission.
   *
   */
  successState: boolean
  /**
   *  Context based function used for altering the custom cursors state.
   *
   */
  setCursorState: React.Dispatch<React.SetStateAction<string>>
}

// [5]. Validity field IDs

/**
 * The IDs of fields that need to be checked for validty before the form can be submitted.
 */

export const validityFieldIDs = ["contact-name", "contact-phone-number", "contact-email", "contact-message", "contact-gdpr"]
