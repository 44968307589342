import * as React from "react"
import { useState } from "react"
import { ThemeProvider } from "styled-components"
import { NewsletterProvider } from "./newsletterContext"
import { CursorProvider } from "./cursorContext"
import { ScrollSafeProvider } from "./scrollSafeContext"
import { NavProvider } from "./navContext"
import { SapProvider } from "./sapContext"
import { darkTheme } from "../templates/themes"
import { SapButtonSafeProvider } from "./sapButtonSafeContext"
import { DeviceProvider } from "./deviceContext"

interface Props {
  children: React.ReactNode
}

const GlobalProvider: React.FunctionComponent<Props> = ({ children }) => {
  const [navState, setNavState] = useState(false)
  const [cursorState, setCursorState] = useState("")
  const [newsletterState, setNewsletterState] = useState(false)
  const [scrollSafe, setScrollSafe] = useState(false)
  const [sapState, setSapState] = useState("closed")
  const [sapButtonSafe, setSapButtonSafe] = useState(true)
  const [deviceState, setDeviceState] = useState("")

  return (
    <ScrollSafeProvider value={{ scrollSafe, setScrollSafe }}>
      <ThemeProvider theme={darkTheme}>
        <DeviceProvider value={{ deviceState, setDeviceState }}>
          <CursorProvider value={{ cursorState, setCursorState }}>
            <NavProvider value={{ navState, setNavState }}>
              <NewsletterProvider value={{ newsletterState, setNewsletterState }}>
                <SapProvider value={{ sapState, setSapState }}>
                  <SapButtonSafeProvider value={{ sapButtonSafe, setSapButtonSafe }}>
                    {/* */}
                    {children}
                    {/* */}
                  </SapButtonSafeProvider>
                </SapProvider>
              </NewsletterProvider>
            </NavProvider>
          </CursorProvider>
        </DeviceProvider>
      </ThemeProvider>
    </ScrollSafeProvider>
  )
}

export default GlobalProvider
