import * as React from "react"
import { useState, useEffect, useRef, useContext } from "react"
import CursorContext from "../../../context/cursorContext"

// Components
import { Container } from "../../StyledComponents/layoutComponentStyles"
import { SapFormLabel, MultiInputRow } from "../../StyledComponents/sapStyledComponents"
import { $mobile } from "../../../utils/breakpoints"

// Animations
import { stepTitleAnimDelayed, sapFadeAnim, fieldVars } from "../../../animations/pages/sapAnim"
import { createRevealTitle } from "../../../animations/globalAnims"
import { AnimatePresence, motion } from "framer-motion"

// Interfaces
import { SapFormFieldProps } from "../StartAProject"

/*









*/

const ScheduleCallField: React.FunctionComponent<SapFormFieldProps> = ({ step, stepForward, positionInForm, handleChange }) => {
  // Context
  const { setCursorState } = useContext(CursorContext)

  // Refs
  const subTitleRef = useRef<HTMLParagraphElement>(null)
  const titleRef = useRef<HTMLHeadingElement>(null)
  const rowRef = useRef<any>(null)

  // States
  const [dateInputState, setDateInputState] = useState(false)
  const [timeInputState, setTimeInputState] = useState(false)
  const [advanceArrowState, setAdvanceArrowState] = useState(false)

  // Effects
  useEffect(() => {
    if (step === positionInForm) {
      createRevealTitle(subTitleRef.current)
      stepTitleAnimDelayed(titleRef.current)
      sapFadeAnim(rowRef.current)
    }
  }, [step])

  useEffect(() => {
    if (dateInputState && timeInputState) {
      setAdvanceArrowState(true)
    }
  }, [dateInputState, timeInputState])

  return (
    <AnimatePresence exitBeforeEnter>
      {step === positionInForm && (
        <motion.div className='o-form-step' initial='initial' animate='animate' exit='exit' variants={fieldVars}>
          <Container tenClm>
            <p className='o-par-lrg' ref={subTitleRef}>
              Time to make this thing official...
            </p>
            <h2 style={{ marginBottom: "4rem" }} ref={titleRef}>
              When suits you best?
            </h2>
            <fieldset>
              <MultiInputRow ref={rowRef}>
                <p className={!$mobile ? "o-par-lrg" : undefined}>Let's talk at</p>
                <div style={{ position: "relative" }}>
                  <SapFormLabel
                    htmlFor='call-time'
                    id='call-time-label'
                    onMouseEnter={() => setCursorState?.("is-link")}
                    onMouseLeave={() => setCursorState?.("")}
                  >
                    Time*
                  </SapFormLabel>
                  <input
                    type='time'
                    id='call-time'
                    name={`4_b. Call Time`}
                    className='c-sap-input is-schedule'
                    onChange={e => {
                      handleChange(e)

                      if (e.target.value !== "") {
                        setTimeInputState?.(true)
                      }
                    }}
                    onFocus={e => {
                      document.getElementById("call-time-label")!.style.opacity = "0"
                      e.target.classList.add("is-visited")
                    }}
                    onBlur={() => (document.getElementById("call-time-label")!.style.opacity = "0")}
                    onMouseEnter={() => setCursorState?.("is-link")}
                    onMouseLeave={() => setCursorState?.("")}
                  />
                </div>
                <p className={!$mobile ? "o-par-lrg" : undefined}>on</p>
                <div style={{ position: "relative" }}>
                  <SapFormLabel
                    htmlFor='call-date'
                    id='call-date-label'
                    onMouseEnter={() => setCursorState?.("is-link")}
                    onMouseLeave={() => setCursorState?.("")}
                  >
                    Date*
                  </SapFormLabel>
                  <input
                    type='date'
                    id='call-date'
                    name={`4_a. Call Date`}
                    className='c-sap-input is-schedule'
                    onChange={e => {
                      handleChange(e)
                      if (e.target.value !== "") {
                        setDateInputState?.(true)
                      }
                    }}
                    onMouseEnter={() => setCursorState?.("is-link")}
                    onMouseLeave={() => setCursorState?.("")}
                    onFocus={e => {
                      document.getElementById("call-date-label")!.style.opacity = "0"
                      e.target.classList.add("is-visited")
                    }}
                    onBlur={() => (document.getElementById("call-date-label")!.style.opacity = "0")}
                  />
                  <button
                    className={advanceArrowState ? "c-advance-arrow is-visible" : "c-advance-arrow"}
                    onClick={e => {
                      e.preventDefault()
                      stepForward?.()
                    }}
                    onMouseEnter={() => setCursorState?.("is-link")}
                    onMouseLeave={() => setCursorState?.("")}
                  >
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 113.39 73.62'>
                      <line
                        y1='36.81'
                        x2='112.87'
                        y2='36.81'
                        fill='none'
                        stroke='rgba(250, 250, 250, 1)'
                        strokeMiterlimit='10'
                        strokeWidth='4.5'
                      />
                      <path
                        d='M76.58,0a36.81,36.81,0,0,0,36.81,36.81A36.81,36.81,0,0,0,76.58,73.62'
                        fill='none'
                        stroke='rgba(250, 250, 250, 1)'
                        strokeMiterlimit='10'
                        strokeWidth='4.5'
                      />
                    </svg>
                  </button>
                </div>
              </MultiInputRow>
            </fieldset>
          </Container>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default ScheduleCallField
