import * as React from "react"

// Styles
import "./_spinner.scss"

// Components

// Animations
import { motion, MotionStyle, Variants } from "framer-motion"

// Interfaces
interface SpinnerProps {
  variants?: Variants
}

/*









*/

const Spinner: React.FunctionComponent<SpinnerProps> = ({ variants }) => {
  const style: MotionStyle = {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  }

  return (
    <motion.div initial='initial' animate='animate' exit='animate' variants={variants} style={style}>
      <svg className='o-spinner' viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg'>
        <circle fill='none' strokeWidth='6' strokeLinecap='round' cx='33' cy='33' r='30'></circle>
      </svg>
    </motion.div>
  )
}

export default Spinner
