import * as React from "react"
import { useEffect, useRef } from "react"

// Components
import { SapPillButton } from "../../Buttons/pillButton"

// Animations
import { stepTitleAnimDelayed, sapBtnAnim, fieldVars } from "../../../animations/pages/sapAnim"
import { createRevealTitle } from "../../../animations/globalAnims"
import { AnimatePresence, motion } from "framer-motion"

// Interfaces
import { SapFormFieldProps } from "../StartAProject"

/*









*/

const BudgetField: React.FunctionComponent<SapFormFieldProps> = ({ step, stepForward, positionInForm, handleChange, SapFormType }) => {
  // Refs
  const subTitleRef = useRef<HTMLParagraphElement>(null)
  const titleRef = useRef<HTMLHeadingElement>(null)

  // Effects
  useEffect(() => {
    const btns: Array<HTMLElement> = Array.from(document.querySelectorAll(".pill-btn.step-2"))
    if (step === positionInForm) {
      createRevealTitle(subTitleRef.current)
      stepTitleAnimDelayed(titleRef.current)
      sapBtnAnim(btns)
    }
  }, [step])

  return (
    <AnimatePresence exitBeforeEnter>
      {step === positionInForm && (
        <motion.div className='o-form-step' initial='initial' animate='animate' exit='exit' variants={fieldVars}>
          <p ref={subTitleRef} className='o-par-lrg'>
            Nice one! Now, without wanting to seem too forward...
          </p>
          <h2 ref={titleRef}>What’s you’re budget for this project?</h2>
          <fieldset id={`2. ${SapFormType} Budget`}>
            <div className='o-four-btn-grid'>
              <SapPillButton
                step={2}
                stepForward={stepForward}
                id={"small-budget"}
                value={"£2,500 - £4,999"}
                name={`2. ${SapFormType} Budget`}
                handleChange={handleChange}
              />
              <SapPillButton
                step={2}
                stepForward={stepForward}
                id={"medium-budget"}
                value={"£5,000 - £7,499"}
                name={`2. ${SapFormType} Budget`}
                handleChange={handleChange}
              />
              <SapPillButton
                step={2}
                stepForward={stepForward}
                id={"large-budget"}
                value={"£7,500 - £10,000"}
                name={`2. ${SapFormType} Budget`}
                handleChange={handleChange}
              />
              <SapPillButton
                step={2}
                stepForward={stepForward}
                id={"xlarge-budget"}
                value={"£10,000+"}
                name={`2. ${SapFormType} Budget`}
                handleChange={handleChange}
              />
            </div>
          </fieldset>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default BudgetField
