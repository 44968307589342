import * as React from "react"
import { useContext } from "react"

// Styles
import "./_start-a-project.scss"

// Components
import SAPButton from "../Buttons/sapButton"
import SapCall from "./SapCall"
import SapLanding from "./SapLanding"
import SapProposal from "./SapProposal"
import CursorContext from "../../context/cursorContext"
import SapContext from "../../context/sapContext"

import { SapContainer, SapSiteContactDetails } from "../StyledComponents/sapStyledComponents"

// Animation
import { AnimatePresence } from "framer-motion"
import { $speedP1 } from "../../animations/animUtils"
import { $mobile } from "../../utils/breakpoints"

// Variants
const sapVars = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: { duration: 0.1, ease: "linear", delay: 0.2 },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: $speedP1,
      ease: "linear",
    },
  },
}

const startVars = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: { duration: 0.1, ease: "linear", delay: 0.45 },
  },
  exit: { opacity: 1, transition: { duration: $speedP1, ease: "linear" } },
}

// Interfaces
interface StartAProjectProps {
  landingExitAnim: () => void
}

export interface SapFormFieldProps {
  /**
   *  State based number used to dictate the users position in a form.
   */
  step?: number
  /**
   *  Array based number denoting the fields place in the form. This should be the question number -1.
   */
  positionInForm: number
  /**
   *  Helper function used to move a form state to its next step.
   */
  stepForward?: (() => void) | undefined
  /**
   *  State setting function used to determine whether a time input field has been filled in.
   */
  setTimeInputState?: React.Dispatch<React.SetStateAction<boolean>>
  /**
   *  State setting function used to detewrmine whether a date input field has been filled in.
   */
  setDateInputState?: React.Dispatch<React.SetStateAction<boolean>>
  /**
   *  State based boolean used to to display an advance arrow, only if a forms setTimeInputState && setDateInputState are true.
   */
  advanceArrowState?: boolean
  /**
   *  onChange function for input fields.
   */
  handleChange?: any
  /**
   *  Dictates whether the form is requesting proposal or call (for use with netlify forms)
   */
  SapFormType?: string
}

/*








*/

const StartAProject: React.FunctionComponent<StartAProjectProps> = ({ landingExitAnim }) => {
  // Context
  const { sapState } = useContext(SapContext)

  // States
  const states = {
    start: sapState === "start",
    call: sapState === "call",
    proposal: sapState === "proposal",
    isOpen: sapState === "start" || sapState === "call" || sapState === "proposal",
  }

  return (
    <>
      <SAPButton landingExitAnim={landingExitAnim} />

      <AnimatePresence exitBeforeEnter>
        {states.start && (
          <SapContainer key='sap-landing' initial='initial' animate='animate' variants={startVars} id='sap-landing'>
            <SapLanding />
          </SapContainer>
        )}
        {states.call && (
          <SapContainer key='sap-call' initial='initial' animate='animate' exit='exit' variants={sapVars}>
            <SapCall />
          </SapContainer>
        )}
        {states.proposal && (
          <SapContainer key='sap-proposal' initial='initial' animate='animate' exit='exit' variants={sapVars}>
            <SapProposal />
          </SapContainer>
        )}
      </AnimatePresence>
    </>
  )
}

export default StartAProject

export const SapSiteContactLinks: React.FunctionComponent = () => {
  const { setCursorState } = useContext(CursorContext)

  return (
    <SapSiteContactDetails>
      {/* <a href='tel:+441743224262' onMouseEnter={() => setCursorState?.("is-link")} onMouseLeave={() => setCursorState?.("")}>
        <p className={$mobile ? "o-par-sml" : undefined}>
          <span>T: </span>
          <span>+44 1743 224262</span>
        </p>
      </a> */}
      <a href='mailto:hello@silkstudio.co' onMouseEnter={() => setCursorState?.("is-link")} onMouseLeave={() => setCursorState?.("")}>
        <p className={$mobile ? "o-par-sml" : undefined}>
          <span>E: </span>
          <span>hello@silkstudio.co</span>
        </p>
      </a>
    </SapSiteContactDetails>
  )
}
