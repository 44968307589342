import * as React from "react"

// Styles
import "./_checkbox.scss"

// Interfaces
interface CheckboxProps {
  id: string
  name: string
  value: string
  required?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}
/*









*/

const Checkbox: React.FunctionComponent<CheckboxProps> = ({ id = "", name = "", value = "", required = false, onChange }) => {
  return (
    <div className='o-checkbox'>
      <input type='checkbox' id={id} name={name} value={value} required={required} onChange={onChange} />
      <div className='o-checkmark'></div>
    </div>
  )
}

export default Checkbox
