import * as React from "react"
import { useEffect, useRef } from "react"

// Components
import { SapPillButton } from "../../Buttons/pillButton"

// Animations
import { stepTitleAnimDelayed, sapBtnAnim, fieldVars } from "../../../animations/pages/sapAnim"
import { createRevealTitle } from "../../../animations/globalAnims"
import { AnimatePresence, motion } from "framer-motion"

// Interfaces
import { SapFormFieldProps } from "../StartAProject"

/*









*/

const ReferralField: React.FunctionComponent<SapFormFieldProps> = ({
  step,
  stepForward,
  positionInForm,
  handleChange,
  SapFormType,
}) => {
  const subTitleRef = useRef<HTMLParagraphElement>(null)
  const titleRef = useRef<HTMLHeadingElement>(null)

  useEffect(() => {
    const btns: Array<HTMLElement> = Array.from(document.querySelectorAll(".pill-btn.step-3"))
    if (step === positionInForm) {
      createRevealTitle(subTitleRef.current)
      stepTitleAnimDelayed(titleRef.current)
      sapBtnAnim(btns)
    }
  }, [step])

  return (
    <AnimatePresence exitBeforeEnter>
      {step === positionInForm && (
        <motion.div className='o-form-step' initial='initial' animate='animate' exit='exit' variants={fieldVars}>
          <p ref={subTitleRef} className='o-par-lrg'>
            Who should we thank for sending you here?
          </p>
          <h2 ref={titleRef}>How did you find out about us?</h2>
          <fieldset id={`3. ${SapFormType} Referral`}>
            <div className='o-five-btn-grid'>
              <SapPillButton
                step={3}
                stepForward={stepForward}
                id={"a-project-of-ours"}
                value={"A project of ours"}
                name={`3. ${SapFormType} Referral`}
                handleChange={handleChange}
              />
              <SapPillButton
                step={3}
                stepForward={stepForward}
                id={"business-referral"}
                value={"Business referral"}
                name={`3. ${SapFormType} Referral`}
                handleChange={handleChange}
              />
              <SapPillButton
                step={3}
                stepForward={stepForward}
                id={"advert"}
                value={"An advert"}
                name={`3. ${SapFormType} Referral`}
                handleChange={handleChange}
              />
              <SapPillButton
                step={3}
                stepForward={stepForward}
                id={"search-engine"}
                value={"Search engine"}
                name={`3. ${SapFormType} Referral`}
                handleChange={handleChange}
              />
              <SapPillButton
                step={3}
                stepForward={stepForward}
                id={"word-of-mouth"}
                value={"Word of mouth"}
                name={`3. ${SapFormType} Referral`}
                handleChange={handleChange}
              />
            </div>
          </fieldset>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default ReferralField
