import * as React from "react"

import { useContext } from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import CursorContext from "../../context/cursorContext"

interface StyledWrapBtnProps {
  readonly centered: boolean
  readonly responsiveCentered: boolean
}

interface WrapButtonProps {
  text: string
  link?: string
  clickAction?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  style?: {}
  centered?: boolean
  responsiveCentered?: boolean
}

export const StyledWrapBtn = styled.div<StyledWrapBtnProps>`
  height: 5rem;
  width: calc(100% + 3rem);
  font-size: 1.51rem;
  font-weight: 800;
  margin-top: 1.25rem;
  position: relative;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
  transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  @media screen and (max-width: 1024px) {
    font-size: 2.5rem;
    margin-top: 3.5rem;
    width: calc(100% + 5rem);
    height: 8.5rem;
  }
  @media screen and (max-width: 480px) {
    font-size: 5.2383rem;
    width: calc(100% + 9rem);
    height: 18rem;
  }

  span {
    position: relative;
    color: rgba(250, 250, 250, 1);
    mix-blend-mode: difference;
    z-index: 2;
    transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  }
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    width: 2rem;
    height: 2rem;
    background: ${props => props.theme.textColor};
    border-radius: 1.8rem;
    transform: translate(0, -50%);
    transition: all 0.9s cubic-bezier(0.16, 1, 0.3, 1);
    mix-blend-mode: difference;
    @media screen and (max-width: 1024px) {
      margin-top: 0.5px;
      width: 3.5rem;
      height: 3.5rem;
    }
    @media screen and (max-width: 480px) {
      margin-top: 0.5px;
      width: 6rem;
      height: 6rem;
      border-radius: 6rem;
    }
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 5;
    width: 2rem;
    height: 2rem;
    background-image: url(${props => props.theme.xsArrow});
    background-size: 50% 50%;
    background-position: 55% 55%;
    background-repeat: no-repeat;
    border-radius: 1.8rem;
    transform: translate(0, -50%);
    transition: all 0.9s cubic-bezier(0.16, 1, 0.3, 1);
    overflow: hidden;
    @media screen and (max-width: 1024px) {
      width: 3.5rem;
      height: 3.5rem;
    }
    @media screen and (max-width: 480px) {
      width: 6rem;
      height: 6rem;
      transform: translate(2.5%, -45%);
    }
  }
  &:hover {
    span {
      padding-left: 3rem;
      @media screen and (max-width: 1024px) {
        padding-left: 5rem;
      }
      @media screen and (max-width: 480px) {
        padding-left: 9rem;
      }
    }
  }
  &:hover::before {
    width: 100%;
    height: 100%;
    border-radius: 2.6rem;
    @media screen and (max-width: 1024px) {
      border-radius: 10rem;
    }
    @media screen and (max-width: 480px) {
      border-radius: 10rem;
    }
  }
  &:hover::after {
    right: -2rem;
    @media screen and (max-width: 1024px) {
      right: -3.5rem;
    }
    @media screen and (max-width: 480px) {
      right: -6rem;
    }
  }

  ${props =>
    props.centered &&
    css`
      transform: translate3d(-1rem, 0, 0);
      @media screen and (max-width: 1024px) {
        transform: translate3d(-2.5rem, 0, 0);
      }
      @media screen and (max-width: 480px) {
        transform: translate3d(-4rem, 0, 0);
      }
    `}

  ${props =>
    props.responsiveCentered &&
    css`
      @media screen and (max-width: 1024px) {
        transform: translate3d(-2.5rem, 0, 0);
      }
      @media screen and (max-width: 480px) {
        transform: translate3d(-4rem, 0, 0);
      }
    `}
`

/*









*/

const WrapButton: React.FunctionComponent<WrapButtonProps> = ({
  text,
  link,
  clickAction,
  style = {},
  centered = false,
  responsiveCentered = false,
}) => {
  const { setCursorState } = useContext(CursorContext)

  return (
    <Link
      to={link!}
      style={{
        textDecoration: "none",
        color: "inherit",
      }}
      onMouseEnter={() => setCursorState?.("is-link")}
      onMouseLeave={() => setCursorState?.("")}
      onClick={e => {
        setCursorState?.("")
        clickAction?.(e)
      }}
    >
      <div style={{ display: "inline-block" }}>
        <StyledWrapBtn centered={centered} responsiveCentered={responsiveCentered} style={style}>
          <span>{text}</span>
        </StyledWrapBtn>
      </div>
    </Link>
  )
}

export default WrapButton
