import * as React from "react"

import { useState, useEffect, useContext } from "react"
import CursorContext from "../../context/cursorContext"
import { $desktop } from "../../utils/breakpoints"
import "./_cursor.scss"

/**
 * Idea for waiting cursor
 *
 * In handleText function, also set body cursor to 'wait' or something similar
 *
 */

/*
 
 
 
 
 
 
 
 
 
 */

const CustomCursor = () => {
  // Context
  const { cursorState } = useContext(CursorContext)

  // States
  const [mousePos, setMousePos] = useState({ x: 400, y: 400 })
  const [cursorText, setCursorText] = useState("")

  // Effects
  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove)

    return () => {
      document.removeEventListener("mousemove", onMouseMove)
    }
  }, [])
  useEffect(() => {
    const handleText = () => {
      if (cursorState === "is-case") {
        setCursorText("View")
      } else if (cursorState === "is-post") {
        setCursorText("Read")
      } else if (cursorState === "is-drag" || cursorState === "is-drag is-dragging") {
        setCursorText("Drag")
      } else if (cursorState === "is-link" || cursorState === "") {
        setCursorText("")
      }
    }

    handleText()
  }, [cursorState])

  // Helper Functions
  const onMouseMove = (event: MouseEvent) => {
    const { clientX: x, clientY: y } = event
    setMousePos({ x, y })
  }

  return (
    <>
      {$desktop && (
        <div
          className={`o-cursor${cursorState !== "" ? ` ${cursorState}` : ""}`}
          style={{
            transform: `translate(${mousePos.x}px, ${mousePos.y}px)`,
          }}
        >
          <span className='o-par-sml'>{cursorText}</span>
        </div>
      )}
    </>
  )
}

export default CustomCursor

/*





























*/
// const initCursor = () => {
//     // add listener to track the current mouse position
//     document.addEventListener("mousemove", event => {
//       clientX = event.clientX
//       clientY = event.clientY
//     })

//     use requestAnimationFrame() for smooth performance
//         const render = () => {
//           document.getElementById(
//             "cursor"
//           ).style.transform = `translate(${mousePos.x}px, ${mousePos.y}px)`

//           requestAnimationFrame(render)
//         }
//         requestAnimationFrame(render)
//       }
