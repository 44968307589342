export const $desktop = typeof window !== `undefined` ? window.innerWidth >= 1025 : null

export const $tablet = typeof window !== `undefined` ? window.innerWidth <= 1024 && window.innerWidth >= 481 : null

export const $mobile = typeof window !== `undefined` ? window.innerWidth <= 480 : null

// export const checkDevice = (setDeviceState: React.Dispatch<React.SetStateAction<string>> | undefined) => {
//   if (window.innerWidth > 1024) {
//     setDeviceState?.("desktop")
//     console.log("Device: desktop")
//   }

//   if (window.innerWidth <= 1024 && window.innerWidth > 480) {
//     setDeviceState?.("tablet")
//     console.log("Device: tablet")
//   }

//   if (window.innerWidth <= 480) {
//     setDeviceState?.("mobile")
//     console.log("Device: mobile")
//   }
// }
