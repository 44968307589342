import * as React from "react"
import { FaTwitter, FaInstagram, FaDribbble, FaBehance, FaLinkedin } from "react-icons/fa"
import { socialAccount } from "../@types/global-types"

export const socialAccounts: Array<socialAccount> = [
  {
    accType: "instagram",
    username: "madebysilkstudio",
    link: "https://www.instagram.com/madebysilkstudio",
    buttonText: "Instagram",
    icon: <FaInstagram />,
  },

  {
    accType: "dribbble",
    username: "silkstudio",
    link: "https://dribbble.com/madebysilkstudio",
    buttonText: "Dribbble",
    icon: <FaDribbble />,
  },

  {
    accType: "behance",
    username: "silkstudio",
    link: "https://www.behance.net/madebysilkstudio",
    buttonText: "Behance",
    icon: <FaBehance />,
  },

  {
    accType: "twitter",
    username: "bysilkstudio",
    link: "https://www.twitter.com/bysilkstudio",
    buttonText: "Twitter",
    icon: <FaTwitter />,
  },

  {
    accType: "linkedIn",
    username: "silkstudio",
    link: "https://www.linkedin.com/madebysilkstudio",
    buttonText: "LinkedIn",
    icon: <FaLinkedin />,
  },
]
