import * as React from "react"
import { useEffect, useRef, useContext } from "react"
import CursorContext from "../../../context/cursorContext"

// Components
import SubmitButton from "../../Buttons/submitButton"
import autosize from "autosize"
import { Container, FlexRow } from "../../StyledComponents/layoutComponentStyles"

// Styled Components
import { SapUserContactInfo } from "../../StyledComponents/sapStyledComponents"

// Animations
import { sapContactFormAnim, fieldVars } from "../../../animations/pages/sapAnim"
import { createRevealTitle } from "../../../animations/globalAnims"
import { $desktop, $mobile, $tablet } from "../../../utils/breakpoints"
import { AnimatePresence, motion } from "framer-motion"

// Interfaces
import { SapFormFieldProps } from "../StartAProject"
import Checkbox from "../../Checkbox"

// Helper functions
const handleFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  const label = Array.from(event!.currentTarget!.parentElement!.children).find(elem => elem.tagName === "LABEL")
  label?.classList.add("is-active")
}

const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  if (event.currentTarget.value) {
    return
  } else {
    const label = Array.from(event?.currentTarget?.parentElement!.children).find(elem => elem.tagName === "LABEL")
    label?.classList.remove("is-active")
  }
}

const parDesktopStyles = {
  opacity: 1,
  fontSize: "2.0364rem",
  fontWeight: 400,
}

const parMobileStyles = {
  marginBottom: "10rem",
  fontSize: "9.0507rem",
  lineHeight: "135%",
  fontWeight: 800,
  opacity: 1,
}

/*









*/

const UserInfoField: React.FunctionComponent<SapFormFieldProps> = ({ step, handleChange, positionInForm, SapFormType }) => {
  // Context
  const { setCursorState } = useContext(CursorContext)

  // Refs
  const subTitleRef = useRef<HTMLParagraphElement>(null)
  const submitRef = useRef<HTMLDivElement>(null)
  const formRef = useRef<any>(null)

  // Effects
  useEffect(() => {
    if (step === positionInForm) {
      createRevealTitle(subTitleRef.current)
      sapContactFormAnim(formRef.current, submitRef.current)
      autosize(document.querySelector("#sap-message"))
    }
  }, [step])

  // Helpers
  const validityFieldIDs: Array<string> = [
    `sap-${SapFormType}-first-name`,
    `sap-${SapFormType}-last-name`,
    `sap-${SapFormType}-phone-number`,
    `sap-${SapFormType}-email`,
    `sap-${SapFormType}-company`,
    `sap-${SapFormType}-deadline`,
    `sap-${SapFormType}-message`,
    `sap-${SapFormType}-gdpr`,
  ]

  return (
    <AnimatePresence exitBeforeEnter>
      {step === positionInForm && (
        <motion.div className='o-form-step is-userInfo' initial='initial' animate='animate' exit='exit' variants={fieldVars}>
          <Container
            tenClm={$desktop ? true : false}
            twelveClm={$tablet ? true : false}
            fourteenClm={$mobile ? true : false}
            id='user-details'
          >
            <p className='o-par-lrg' ref={subTitleRef} style={$mobile ? parMobileStyles : parDesktopStyles}>
              And finally, tell us a little bit about you...
            </p>

            <SapUserContactInfo ref={formRef}>
              <div className='c-lrg-form__field'>
                <label className='c-lrg-form__label' htmlFor={`sap-${SapFormType}-first-name`}>
                  First name*
                </label>
                <input
                  type='text'
                  id={`sap-${SapFormType}-first-name`}
                  name={`5_a. ${SapFormType} First Name`}
                  className='c-lrg-form__input'
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onMouseEnter={() => setCursorState?.("is-link")}
                  onMouseLeave={() => setCursorState?.("")}
                  autoComplete='off'
                  required
                />
              </div>
              <div className='c-lrg-form__field'>
                <label className='c-lrg-form__label' htmlFor={`sap-${SapFormType}-last-name`}>
                  Last name*
                </label>
                <input
                  type='text'
                  id={`sap-${SapFormType}-last-name`}
                  name={`5_b. ${SapFormType} Last Name`}
                  className='c-lrg-form__input'
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onMouseEnter={() => setCursorState?.("is-link")}
                  onMouseLeave={() => setCursorState?.("")}
                  autoComplete='off'
                  required
                />
              </div>
              <div className='c-lrg-form__field'>
                <label className='c-lrg-form__label' htmlFor={`sap-${SapFormType}-phone-number`}>
                  Phone number*
                </label>
                <input
                  type='tel'
                  id={`sap-${SapFormType}-phone-number`}
                  name={`5_c. ${SapFormType} Phone Number`}
                  className='c-lrg-form__input'
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onMouseEnter={() => setCursorState?.("is-link")}
                  onMouseLeave={() => setCursorState?.("")}
                  autoComplete='off'
                  required
                />
              </div>
              <div className='c-lrg-form__field'>
                <label className='c-lrg-form__label' htmlFor={`sap-${SapFormType}-email`}>
                  Email*
                </label>
                <input
                  type='email'
                  id={`sap-${SapFormType}-email`}
                  name={`5_d. ${SapFormType} Email`}
                  className='c-lrg-form__input'
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onMouseEnter={() => setCursorState?.("is-link")}
                  onMouseLeave={() => setCursorState?.("")}
                  autoComplete='off'
                  required
                />
              </div>
              <div className='c-lrg-form__field'>
                <label className='c-lrg-form__label' htmlFor={`sap-${SapFormType}-company`}>
                  Company
                </label>
                <input
                  type='text'
                  id={`sap-${SapFormType}-company`}
                  name={`5_e. ${SapFormType} Company`}
                  className='c-lrg-form__input'
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onMouseEnter={() => setCursorState?.("is-link")}
                  onMouseLeave={() => setCursorState?.("")}
                  autoComplete='off'
                />
              </div>
              <div className='c-lrg-form__field'>
                <label className='c-lrg-form__label' htmlFor={`sap-${SapFormType}-deadline`}>
                  Deadline
                </label>
                <input
                  type='date'
                  id={`sap-${SapFormType}-deadline`}
                  name={`5_f. ${SapFormType} Deadline`}
                  className='c-lrg-form__input'
                  onChange={handleChange}
                  onFocus={e => {
                    e.target.classList.add("is-visited")
                    handleFocus(e)
                  }}
                  onBlur={e => {
                    const hasValue = e.target.value !== ""
                    const isVisited = e.target.classList.contains("is-visited")

                    if (!hasValue && isVisited) {
                      e.target.classList.remove("is-visited")
                    }

                    handleBlur(e)
                  }}
                  onMouseEnter={() => setCursorState?.("is-link")}
                  onMouseLeave={() => setCursorState?.("")}
                  autoComplete='off'
                />
              </div>
              <div className='c-lrg-form__field'>
                <label className='c-lrg-form__label' htmlFor={`sap-${SapFormType}-message`}>
                  Message*
                </label>
                <textarea
                  id={`sap-${SapFormType}-message`}
                  name={`5_g. ${SapFormType} Message`}
                  className='c-lrg-form__input'
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onMouseEnter={() => setCursorState?.("is-link")}
                  onMouseLeave={() => setCursorState?.("")}
                  autoComplete='off'
                  required
                />
              </div>
              <div className='c-lrg-form__field is-gdpr'>
                <div onMouseEnter={() => setCursorState?.("is-link")} onMouseLeave={() => setCursorState?.("")} role='presentation'>
                  <Checkbox
                    id={`sap-${SapFormType}-gdpr`}
                    name={`${SapFormType} GDPR`}
                    value='true'
                    required={true}
                    onChange={handleChange}
                  />
                </div>

                <p className='o-par-sml' style={{ fontStyle: "italic", opacity: "0.5", textAlign: "left" }}>
                  I agree to the{" "}
                  <a
                    href={"/terms"}
                    // style={{ textDecoration: "underline" }}
                    className='o-inline-link is-bodylink'
                    target='_blank'
                    rel='noopener noreferrer'
                    onMouseEnter={() => setCursorState?.("is-link")}
                    onMouseLeave={() => setCursorState?.("")}
                  >
                    privacy policy
                  </a>{" "}
                  & consent to my details being collected.
                </p>
              </div>
            </SapUserContactInfo>
          </Container>
          <FlexRow>
            <div ref={submitRef}>
              <SubmitButton
                text='Submit'
                validityFieldIDs={validityFieldIDs}
                style={{
                  transform: !$mobile ? "translate3d(-1rem,0,0)" : "translate3d(-3rem,0,0)",
                }}
              />
            </div>
          </FlexRow>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default UserInfoField
