import * as React from "react"
import { useState, useEffect, useContext } from "react"
import { Link } from "gatsby"

// Context
import NewsletterContext from "../../context/newsletterContext"
import CursorContext from "../../context/cursorContext"

// Componenets
import MagneticMouse from "../MagneticMouse"
import { FaPaperPlane } from "react-icons/fa"
import { FlexRow } from "../StyledComponents/layoutComponentStyles"
import { socialAccounts } from "../../utils/socialAccounts"

// Styles
import "./_navigation.scss"
import { $mobile } from "../../utils/breakpoints"

// Animation
import { motion, AnimatePresence } from "framer-motion"
import { $speedP2, easeInOutExpo, easeOutExpo } from "../../animations/animUtils"
import NavContext from "../../context/navContext"
import { socialAccount } from "../../@types/global-types"

// Variants

const bodyVars = {
  initial: { y: "100%" },
  animate: { y: 0, transition: { duration: 0.9, ease: easeInOutExpo } },
  exit: {
    y: "-100%",
    transition: { delay: 0.3, duration: 0.9, ease: easeInOutExpo },
  },
}
const linkContainer = {
  initial: {},
  animate: { transition: { staggerChildren: 0.2, delayChildren: 0.525 } },
  exit: { transition: { staggerChildren: 0.125 } },
}
const navLink = {
  initial: {
    y: "100%",
    opacity: 1,
  },
  animate: {
    y: "0%",
    transition: {
      duration: 1.2,
      ease: easeOutExpo,
    },
  },
  exit: {
    y: "-100%",
    opacity: 0,
    transition: { duration: 0.9, ease: easeInOutExpo },
  },
}
const socialLinkContainer = {
  initial: {},
  animate: { transition: { staggerChildren: 0.125, delayChildren: 0.7 } },
  exit: { transition: { staggerChildren: 0.075 } },
}
const socialLink = {
  initial: {
    y: "150%",
  },
  animate: {
    y: "0%",
    transition: {
      duration: 0.9,
      ease: easeOutExpo,
    },
  },
  exit: {
    y: "-150%",
    opacity: 0,
    transition: { duration: $speedP2, ease: easeInOutExpo },
  },
}

// Social accounts

interface NavigationProps {
  setCursorState?: React.Dispatch<React.SetStateAction<string>>
  newsletterState?: boolean
  setNewsletterState?: React.Dispatch<React.SetStateAction<boolean>>
  navState?: boolean
  setNavState?: React.Dispatch<React.SetStateAction<boolean>>
}

/*









*/

export const NavLinks: React.FunctionComponent<NavigationProps> = ({ setCursorState, setNavState }) => {
  return (
    <motion.div variants={linkContainer} initial='initial' animate='animate' exit='exit' className='c-nav-links-container'>
      <div className='c-nav-link__wrapper'>
        {/* @ts-expect-error */}
        <Link
          to='/work/'
          className='c-nav__link'
          onClick={() => setNavState?.(false)}
          onMouseEnter={() => setCursorState?.("is-link")}
          onMouseLeave={() => setCursorState?.("")}
        >
          <motion.div variants={navLink}>
            <FlexRow justifyStart mobileDirColumn mobileAlignStart>
              <div className='o-accent-wrapper'>
                <p className='o-accent'>01</p>
              </div>
              <h2 className='o-outline-type'>Work</h2>
            </FlexRow>
          </motion.div>
        </Link>
      </div>
      <div className='c-nav-link__wrapper'>
        {/* @ts-expect-error */}
        <Link
          to='/studio/'
          className='c-nav__link'
          onClick={() => setNavState?.(false)}
          onMouseEnter={() => setCursorState?.("is-link")}
          onMouseLeave={() => setCursorState?.("")}
        >
          <motion.div variants={navLink}>
            <FlexRow justifyStart mobileDirColumn mobileAlignStart>
              <div className='o-accent-wrapper'>
                <p className='o-accent'>02</p>
              </div>
              <h2 className='o-outline-type'>Studio</h2>
            </FlexRow>
          </motion.div>
        </Link>
      </div>
      <div className='c-nav-link__wrapper'>
        {/* @ts-expect-error */}
        <Link
          to='/journal/'
          className='c-nav__link'
          onClick={() => setNavState?.(false)}
          onMouseEnter={() => setCursorState?.("is-link")}
          onMouseLeave={() => setCursorState?.("")}
        >
          <motion.div variants={navLink}>
            <FlexRow justifyStart mobileDirColumn mobileAlignStart>
              <div className='o-accent-wrapper'>
                <p className='o-accent'>03</p>
              </div>
              <h2 className='o-outline-type'>Journal</h2>
            </FlexRow>
          </motion.div>
        </Link>
      </div>
      <div className='c-nav-link__wrapper'>
        {/* @ts-expect-error */}
        <Link
          to='/contact/'
          className='c-nav__link'
          onClick={() => setNavState?.(false)}
          onMouseEnter={() => setCursorState?.("is-link")}
          onMouseLeave={() => setCursorState?.("")}
        >
          <motion.div variants={navLink}>
            <FlexRow justifyStart mobileDirColumn mobileAlignStart>
              <div className='o-accent-wrapper'>
                <p className='o-accent'>04</p>
              </div>{" "}
              <h2 className='o-outline-type'>Contact</h2>
            </FlexRow>
          </motion.div>
        </Link>
      </div>
    </motion.div>
  )
}

export const NavSocialLinks: React.FunctionComponent<NavigationProps> = ({ setNewsletterState, newsletterState }) => {
  return (
    <motion.div variants={socialLinkContainer} initial='initial' animate='animate' exit='exit' className='c-nav__social-links'>
      {socialAccounts.map((account: socialAccount) => (
        <div key={account.link}>
          <MagneticMouse>
            <div className='c-nav-link__wrapper'>
              <motion.div variants={socialLink}>
                <a href={account.link} style={{ textDecoration: "none" }}>
                  {!$mobile ? <p className='o-par-lrg'>{account.buttonText}</p> : account.icon}
                </a>
              </motion.div>
            </div>
          </MagneticMouse>
        </div>
      ))}
      <div>
        <MagneticMouse>
          <div className='c-nav-link__wrapper'>
            <motion.div variants={socialLink} onClick={() => setNewsletterState?.(!newsletterState)}>
              {!$mobile ? <p className='o-par-lrg'>Newsletter</p> : <FaPaperPlane />}
            </motion.div>
          </div>
        </MagneticMouse>
      </div>
    </motion.div>
  )
}

export const NavFooter: React.FunctionComponent<NavigationProps> = ({ setCursorState }) => {
  // States
  const [lfmData, updateLfmData] = useState<any>({})

  // Helper Functions
  const buildLastFmData = (): {
    name: string
    artist: string
  } => {
    const { error } = lfmData
    const track = lfmData?.recenttracks?.track
    const data = { name: "", artist: "" }

    if (error) {
      data.name = "error"

      return data
    }

    if (!track) {
      data.name = "loading..."
      return data
    }

    const [{ name: songName = "", artist: { "#text": artistName = "" } = {} } = {}] = track

    data.name = songName
    data.artist = artistName

    return data
  }

  // Effects
  useEffect(() => {
    fetch(
      `https://ws.audioscrobbler.com/2.0/?method=user.getRecentTracks&user=nathanhughes57&api_key=${process.env.GATSBY_LASTFM_KEY}&limit=1&nowplaying=true&format=json`
    )
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw new Error("error")
      })
      .then(data => {
        updateLfmData(data)
        buildLastFmData()
      })
      .catch(() =>
        updateLfmData({
          error: "Whoops! Something went wrong...",
        })
      )
  }, [])

  return (
    <motion.div
      className='nav-footer'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, ease: "linear", delay: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.4, delay: 0 } }}
    >
      <FlexRow justifySpaceBetween alignEnd>
        <div className='contact-info'>
          <a
            href='mailto:hello@silkstudio.co'
            style={{ textDecoration: "none", color: "inherit" }}
            onMouseEnter={() => setCursorState?.("is-link")}
            onMouseLeave={() => setCursorState?.("")}
          >
            <p>
              <span>E: </span>
              <span> hello@silkstudio.co</span>
            </p>
          </a>
          {/* <a
            href='tel:+441743224262'
            style={{ textDecoration: "none", color: "inherit" }}
            onMouseEnter={() => setCursorState?.("is-link")}
            onMouseLeave={() => setCursorState?.("")}
          >
            <p>
              <span>T: </span>
              <span> +44 1743 224262</span>
            </p>
          </a> */}
        </div>
        <div className='now-playing'>
          <p>Now Playing: </p>
          <div className='now-playing__data'>
            <p>
              {buildLastFmData().name === "loading..." ? "loading..." : `'${buildLastFmData().name}' by ${buildLastFmData().artist}`}
            </p>
          </div>
        </div>
      </FlexRow>
    </motion.div>
  )
}

export const NavSubFooter: React.FunctionComponent<NavigationProps> = ({ setCursorState, navState }) => {
  return (
    <motion.div
      className='c-footer__sub-menu'
      initial={{ opacity: 0 }}
      animate={{ opacity: navState ? 1 : 0 }}
      transition={{
        duration: 1,
        ease: "linear",
        delay: 1.25,
      }}
      exit={{ opacity: 0, transition: { duration: 0.4, delay: 0 } }}
    >
      <FlexRow justifySpaceBetween>
        <p className='o-par-sml'>Silk Studio © {new Date().getFullYear()}</p>
        <div className='c-footer-sub-menu__items'>
          {/* @ts-expect-error */}
          <Link to='/terms/' onMouseEnter={() => setCursorState?.("is-link")} onMouseLeave={() => setCursorState?.("")}>
            <p className='o-par-sml'>Terms</p>
          </Link>
          {/* @ts-expect-error */}
          <Link to='/sitemap.xml' onMouseEnter={() => setCursorState?.("is-link")} onMouseLeave={() => setCursorState?.("")}>
            <p className='o-par-sml'>Sitemap</p>
          </Link>
        </div>
      </FlexRow>
    </motion.div>
  )
}

/*









*/

const Navigation: React.FunctionComponent<NavigationProps> = () => {
  // Context
  const { newsletterState, setNewsletterState } = useContext(NewsletterContext)
  const { navState, setNavState } = useContext(NavContext)

  const { setCursorState } = useContext(CursorContext)

  useEffect(() => {
    const closeMenu = (ev: KeyboardEvent): void => {
      if (ev.keyCode === 27) {
        console.log("true")
      }
    }
    window.addEventListener("keydown", ev => closeMenu(ev))

    return () => {
      window.removeEventListener("keydown", closeMenu)
    }
  }, [])

  return (
    <AnimatePresence>
      {navState && (
        <motion.div
          initial='initial'
          animate='animate'
          exit='exit'
          variants={bodyVars}
          key='navigation'
          className='c-nav'
          style={{ height: `${window.innerHeight}px` }}
        >
          <div className='c-nav__inner'>
            <NavLinks setCursorState={setCursorState} setNavState={setNavState} />
            <NavSocialLinks newsletterState={newsletterState} setNewsletterState={setNewsletterState} />
          </div>
          <NavFooter setCursorState={setCursorState} />
          <NavSubFooter setCursorState={setCursorState} navState={navState} />
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Navigation
