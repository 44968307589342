import * as React from "react"
import { useEffect, useContext } from "react"
import { createGlobalStyle } from "styled-components"
import { globalHistory } from "@reach/router"
import ReactGA from "react-ga"

// Context
import NewsletterContext from "../../context/newsletterContext"
import CursorContext from "../../context/cursorContext"
import NavContext from "../../context/navContext"
import SapContext from "../../context/sapContext"
// import DeviceContext from "../../context/deviceContext"
// import ScrollSafeContext from "../../context/scrollSafeContext"

// Components
import CustomCursor from "../Cursor"
import BodySizeHandler from "../../utils/bodySizeHandler"
import Header from "../Header"
import Navigation from "../Navigation"
import Newsletter from "../Newsletter"
import StartAProject from "../StartAProject"
import Footer from "../Footer"
import CookieConsent from "../CookieConsent"
import { AppContainer, ScrollContainer } from "../StyledComponents/layoutComponentStyles"

// Animations
import { sapSectionExitAnim } from "../../animations/pages/sapAnim"

const GlobalStyle = createGlobalStyle`

html {
  font-size: 0.83vw;
}

body.consent {
  margin: 2.25rem 0 0 0;
}



html, body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
  font-family: "HK Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  transition: background 0.3s linear;
  background-color: ${props => props.theme.backgroundColor};
  overscroll-behavior: none;

}


a:-webkit-any-link {
  color: inherit;
  text-decoration: none;
}

body::-webkit-scrollbar {
  display: none;
}

main {
  position: relative;
  z-index: 1;
  overflow: hidden;
  overscroll-behavior: none;
  min-height: 100vh;
  margin-bottom: 100vh;
  background-color: ${props => props.theme.backgroundColor};

}

header {
  mix-blend-mode: difference;
}
`

interface LayoutProps {
  children: React.ReactNode
}

/*









*/

const App: React.FunctionComponent<LayoutProps> = ({ children }) => {
  // States
  const { setNewsletterState } = useContext(NewsletterContext)
  const { sapState, setSapState } = useContext(SapContext)
  const { navState, setNavState } = useContext(NavContext)
  const { setCursorState } = useContext(CursorContext)

  // Effects
  useEffect(() => {
    document.body.style.overflow = navState || sapState !== "closed" ? "hidden" : "visible"
  }, [navState, sapState])

  useEffect(() => {
    globalHistory.listen(({ action }) => {
      if (action) {
        setNewsletterState?.(false)
        setNavState?.(false)

        if (sapState !== "closed") {
          landingExitAnim()
        }

        setTimeout(() => {
          setCursorState?.("")
        }, 1000)
      }
    })
  })

  ReactGA.initialize("UA-187375682-1")

  /**
   *  Cusror 'wait' functionality — not sure if it works aestetically
  
  const { scrollSafe } = useContext(ScrollSafeContext)

  useEffect(() => {
    if (scrollSafe) {
      document.body.style.cursor = "default"
    } else {
      document.body.style.cursor = "waiting"
    }
  }, [scrollSafe])

   */

  // Helper Fns
  const landingExitAnim = () => {
    if (sapState === "start") {
      sapSectionExitAnim()
      setTimeout(() => {
        setSapState?.("closed")
      }, 450)
    } else {
      setSapState?.("closed")
    }
  }

  return (
    <AppContainer>
      <GlobalStyle />
      <BodySizeHandler />
      <CookieConsent />
      <Header landingExitAnim={landingExitAnim} />
      <Navigation />
      <CustomCursor />
      <Newsletter />
      <StartAProject landingExitAnim={landingExitAnim} />
      <ScrollContainer id='scroll-container' data-scroll-container>
        {children}
      </ScrollContainer>
      <Footer />
    </AppContainer>
  )
}

export default App
