import * as React from "react"
import { useState, useEffect, useContext } from "react"
import CursorContext from "../../context/cursorContext"
import ReactGA from "react-ga"

// Componenets
import ScopeField from "./SapFormFields/ScopeField"
import BudgetField from "./SapFormFields/BudgetField"
import ReferralField from "./SapFormFields/ReferralField"
import UserInfoField from "./SapFormFields/UserInfoField"
import SapSuccess from "./SapSuccess"
import { RiArrowGoBackLine } from "react-icons/ri"
import { SapSiteContactLinks } from "./StartAProject"

// Styled Components
import { SapFormContainer } from "../StyledComponents/sapStyledComponents"

// Helpers
import { encode } from "../../helpers/contactHelpers"

// Animation
import { gsap } from "gsap"
import { AnimatePresence, motion } from "framer-motion"

// Interfaces
interface SapProposalProps {}

// Form Name
const formName = "Request a proposal"

/*








*/

const SapProposal: React.FunctionComponent<SapProposalProps> = () => {
  // Context
  const { setCursorState } = useContext(CursorContext)

  // States
  const [step, setStep] = useState<number>(0)
  const [childArr, setChildArr] = useState<Array<any>>([])
  const [successState, setSuccessState] = useState<boolean>(false)
  const [formResults, setFormResults] = useState({})

  // Effects
  useEffect(() => {
    setChildArr([1, 2, 3, 4])
    setStep(0)
  }, [])

  // Helper Functions
  function stepForward() {
    if (step < childArr.length - 1) {
      let prev = step
      setStep(-1)
      setTimeout(() => {
        setStep(prev + 1)
      }, 600)
    } else {
      setStep(childArr.length - 1)
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setFormResults({ ...formResults, [event.target.name]: event.target.value })
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": formName, ...formResults }),
    })
      .then(() => {
        setTimeout(() => {
          console.log(formResults)
          setSuccessState(true)
        }, 1500)

        ReactGA.event({
          category: "Form",
          action: "Submitted proposal enquiry",
          label: "SAP Proposal Submission",
        })
      })
      .catch(error => alert(error))

    event.preventDefault()
  }

  return (
    <SapFormContainer>
      <AnimatePresence>
        <form
          name={formName}
          method='POST'
          data-netlify='true'
          data-netlify-honeypot='bot-field'
          autoComplete='off'
          onSubmit={handleSubmit}
          key={"form_component"}
        >
          <input type='hidden' name='form-name' value={formName} />
          <p hidden>
            <label id={`${formName}-bot-label`}>
              Don't fill this out if you're human: <input name='bot-field' aria-labelledby={`${formName}-bot-label`} />
            </label>
          </p>
          <AnimatePresence exitBeforeEnter>
            {!successState && (
              <motion.div exit={{ opacity: 0, transition: { duration: 0.15, ease: "linear" } }} key={"form_fields"}>
                <ScopeField
                  step={step}
                  stepForward={stepForward}
                  positionInForm={0}
                  key={0}
                  handleChange={handleChange}
                  SapFormType={"Proposal"}
                />
                <BudgetField
                  step={step}
                  stepForward={stepForward}
                  positionInForm={1}
                  key={1}
                  handleChange={handleChange}
                  SapFormType={"Proposal"}
                />
                <ReferralField
                  step={step}
                  stepForward={stepForward}
                  positionInForm={2}
                  key={2}
                  handleChange={handleChange}
                  SapFormType={"Proposal"}
                />
                <UserInfoField
                  step={step}
                  stepForward={stepForward}
                  positionInForm={3}
                  key={3}
                  handleChange={handleChange}
                  SapFormType={"Proposal"}
                />
              </motion.div>
            )}

            {successState && <SapSuccess key={"success_message"} />}
          </AnimatePresence>
        </form>

        {step !== 0 && !successState && (
          <motion.div
            className='c-project-back-btn__wrapper'
            key='project_back_btn_container'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { ease: "linear", duration: 0.3 } }}
            exit={{ opacity: 0, transition: { ease: "linear", duration: 0.3 } }}
          >
            <motion.button
              className='c-project-back-btn'
              key='project_back_btn'
              onClick={() => {
                if (step > 0) {
                  let prev = step
                  setStep(-1)
                  setTimeout(() => {
                    setStep(prev - 1)
                  }, 300)
                } else {
                  setStep(0)
                }

                gsap.fromTo(".c-project-back-btn", { rotate: "0deg" }, { rotate: "-360deg", duration: 0.6, ease: "back.out" })
              }}
              tabIndex={1}
              onMouseEnter={() => setCursorState?.("is-link")}
              onMouseLeave={() => setCursorState?.("")}
              onMouseUp={() => {
                if (step === 1) setCursorState?.("")
              }}
            >
              <RiArrowGoBackLine />
            </motion.button>
          </motion.div>
        )}
        <SapSiteContactLinks />
      </AnimatePresence>
    </SapFormContainer>
  )
}

export default SapProposal
