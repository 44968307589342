import React from "react"

interface DeviceContextProps {
  deviceState?: string
  setDeviceState?: React.Dispatch<React.SetStateAction<string>>
}

const DeviceContext = React.createContext<DeviceContextProps>({})

export const DeviceProvider = DeviceContext.Provider

export default DeviceContext
