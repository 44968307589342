import * as React from "react"
import { useEffect } from "react"

// Styles
import "../styles/index.scss"

// Components
import App from "./App"
import GlobalProvider from "../context/GlobalProvider"

// Animation
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { SplitText } from "gsap/SplitText"
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"

// Helpers
import { handleSession } from "../helpers/layoutHelpers"
import { handleScrollRestoration } from "../helpers/layoutHelpers"
import { initSmoothScroll } from "../helpers/layoutHelpers"
import { addZoomHandler } from "../helpers/layoutHelpers"
import { removeZoomHandler } from "../helpers/layoutHelpers"
import { hotjar } from "react-hotjar"

// Plugin Registry
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(SplitText)
gsap.registerPlugin(DrawSVGPlugin)

// Interface
interface LayoutProps {
  children: React.ReactNode
}

/*









*/

const Layout: React.FunctionComponent<LayoutProps> = ({ children }) => {
  // Init App
  useEffect(() => {
    handleSession()
    handleScrollRestoration()
    addZoomHandler()
    initSmoothScroll()

    hotjar.initialize(2267768, 6)

    return () => {
      removeZoomHandler()
    }
  }, [])

  return (
    <GlobalProvider>
      <App>
        <main>{children}</main>
      </App>
    </GlobalProvider>
  )
}

export default Layout

/*
























// --- BROWSER ZOOM FUNCTIONALITY --- //
useEffect(() => {
  const rem = (window.innerWidth / 100) * 0.83
  window.addEventListener("resize", () => {
    let windowSize = window.outerWidth / window.innerWidth
    document.documentElement.style.fontSize = `${rem * windowSize}px`
    document.body.style.fontSize = `${rem * windowSize}px`
  })
}, [])

Variables
const difference = scrollData.current - scrollData.rounded
const acceleration = difference / dimensions.width
const velocity = +acceleration
const skew = velocity * 7.5

const el = document.querySelector(".scroll-container")
let style = el.style.transform
let split = style.split(
  /translate3d\(\s*([^ ,]+)\s*,\s*([^ ,]+)\s*,\s*([^ )]+)\s*\)/
)
let numArr = split[2].split(/[px]/)
let val = Math.abs(parseFloat(numArr[0]))

ScrollTop mutation observer
const observer = new MutationObserver(mutations => {
  mutations.forEach(mutation => {
    if (mutation.attributeName === "style") {
      const arr = /^matrix\(([+\-\d.]+), ([+\-\d.]+), ([+\-\d.]+), ([+\-\d.]+), ([+\-\d.]+), ([+\-\d.]+)\)$/
        .exec(mutation.target.style.transform)
        .slice(1)
      const scroll = Math.abs(parseInt(arr[5]))
      if (scroll < 100) {
        setScrollTopState(true)
      } else if (scroll > 100) {
        setScrollTopState(false)
      }
    }
  })
})
*/
