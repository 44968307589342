import React from "react"

interface SapContextProps {
  sapState?: string
  setSapState?: React.Dispatch<React.SetStateAction<string>>
}

const SapContext = React.createContext<SapContextProps>({})

export const SapProvider = SapContext.Provider

export default SapContext
