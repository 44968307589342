import * as React from "react"
import { useContext, useState, useEffect } from "react"
import CursorContext from "../../context/cursorContext"
import styled from "styled-components"

// Components
import Spinner from "../Spinner"

// Styled Componenets
export const StyledSubmitButton = styled(motion.button)`
  height: 5rem;
  width: calc(100% + 3rem);
  font-size: 1.51rem;
  font-weight: 800;

  position: relative;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
  margin-right: auto;
  margin-left: auto;
  background: transparent;
  outline: none;
  border: none;
  @media screen and (max-width: 1024px) {
    font-size: 2.5rem;
    margin-top: 0;
    width: calc(100% + 5rem);
    height: 8.5rem;
  }
  @media screen and (max-width: 480px) {
    font-size: 5.2383rem;
    width: calc(100% + 9rem);
    height: 18rem;
    min-height: 18rem;
  }

  span {
    position: relative;
    color: rgba(250, 250, 250, 1);
    mix-blend-mode: difference;
    z-index: 2;
    transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  }
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    width: 2rem;
    height: 2rem;
    background: ${props => props.theme.textColor};
    border-radius: 1.8rem;
    transform: translate(0, -50%);
    transition: all 0.9s cubic-bezier(0.16, 1, 0.3, 1);
    mix-blend-mode: difference;
    @media screen and (max-width: 1024px) {
      margin-top: 0.5px;
      width: 3.5rem;
      height: 3.5rem;
    }
    @media screen and (max-width: 480px) {
      margin-top: 0.5px;
      width: 6rem;
      height: 6rem;
      border-radius: 6rem;
    }
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 5;
    width: 2rem;
    height: 2rem;
    background-image: url(${props => props.theme.xsArrow});
    background-size: 50% 50%;
    background-position: 55% 55%;
    background-repeat: no-repeat;
    border-radius: 1.8rem;
    transform: translate(0, -50%);
    transition: all 0.9s cubic-bezier(0.16, 1, 0.3, 1);
    overflow: hidden;
    @media screen and (max-width: 1024px) {
      width: 3.5rem;
      height: 3.5rem;
    }
    @media screen and (max-width: 480px) {
      width: 6rem;
      height: 6rem;
      transform: translate(2.5%, -45%);
    }
  }
  &:hover {
    span {
      padding-left: 3rem;
      @media screen and (max-width: 1024px) {
        padding-left: 5rem;
      }
      @media screen and (max-width: 480px) {
        padding-left: 9rem;
      }
    }
  }
  &:hover::before {
    width: 100%;
    height: 100%;
    border-radius: 2.6rem;
    @media screen and (max-width: 1024px) {
      border-radius: 4rem;
    }
    @media screen and (max-width: 480px) {
      border-radius: 10rem;
    }
  }
  &:hover::after {
    right: -2rem;
    @media screen and (max-width: 1024px) {
      right: -3.5rem;
    }
    @media screen and (max-width: 480px) {
      right: -6rem;
    }
  }
`

export const SumbitButtonContainer = styled(motion.div)`
height: 5rem;
min-height 5rem;
position: relative;
display: inline-block;

@media screen and (max-width: 1024px) {
  height: 8.5rem;
  min-height: 8.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 480px) {
  height: 18rem;
  min-height: 18rem;
}


`

// Animations
import { AnimatePresence, motion } from "framer-motion"

// Interfaces
interface SubmitButtonProps {
  text: string
  style: {}
  validityFieldIDs: string[]
}

// Variants
const variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 0.3, ease: "linear" } },
  exit: { opacity: 0, transition: { duration: 0.3, ease: "linear" } },
}

/*









*/

const SubmitButton: React.FunctionComponent<SubmitButtonProps> = ({ text, style, validityFieldIDs }) => {
  // Context
  const { setCursorState } = useContext(CursorContext)

  // States
  const [isLoading, setIsLoading] = useState(false)

  // Effects
  useEffect(() => {
    if (isLoading) {
      const form: HTMLElement | null = document.getElementById("user-details")
      form ? (form.style.opacity = "0.5") : null
      console.log("disable form ran")
    }
  }, [isLoading])

  return (
    <SumbitButtonContainer>
      <AnimatePresence exitBeforeEnter>
        {!isLoading && (
          <StyledSubmitButton
            type='submit'
            style={style}
            key='button'
            initial='initial'
            animate='animate'
            exit='exit'
            variants={variants}
            onMouseEnter={() => setCursorState?.("is-link")}
            onMouseLeave={() => setCursorState?.("")}
            onMouseUp={() => {
              setCursorState?.("")

              const validity = validityFieldIDs.every(value => {
                const el: HTMLInputElement | HTMLTextAreaElement | null = document.querySelector(`#${value}`)
                const valid = el?.checkValidity()
                return valid
              })

              setIsLoading(validity)
            }}
          >
            <span>{text}</span>
          </StyledSubmitButton>
        )}

        {isLoading && <Spinner key='spinner' variants={variants} />}
      </AnimatePresence>
    </SumbitButtonContainer>
  )
}

export default SubmitButton
