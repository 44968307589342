import * as React from "react"
import { useEffect, useContext, useState } from "react"
import ReactGA from "react-ga"

// Context
import NewsletterContext from "../../context/newsletterContext"
import CursorContext from "../../context/cursorContext"

// Styles
import "./_newsletter.scss"

// Components
import Spinner from "../Spinner"

// Helpers
import { encode } from "../../helpers/contactHelpers"

// Animation
import { motion, AnimatePresence } from "framer-motion"
import { $speedP2, easeInOutExpo } from "../../animations/animUtils"
import { fadeInOut } from "../../animations/globalVariants"

// Variants
const newsletterVars = {
  initial: {
    y: "100%",
  },
  animate: {
    y: "0%",
    transition: {
      duration: $speedP2,
      ease: easeInOutExpo,
    },
  },
  exit: {
    y: "100%",
    transition: {
      duration: $speedP2,
      ease: easeInOutExpo,
    },
  },
}

// Interfaces
interface NewsletterProps {}

// Form Name
const formName = "Newsletter"

/*









*/

const Newsletter: React.FunctionComponent<NewsletterProps> = () => {
  // Context
  const { newsletterState, setNewsletterState } = useContext(NewsletterContext)
  const { setCursorState } = useContext(CursorContext)

  // States
  const [successState, setSuccessState] = useState<string>("notSent")
  const [formResults, setFormResults] = useState({})

  // Effects
  useEffect(() => {
    const handleClick = () => setNewsletterState?.(false)

    document.getElementById("scroll-container")?.addEventListener("mousedown", handleClick)

    return () => {
      document.getElementById("scroll-container")?.removeEventListener("mousedown", handleClick)
    }
  }, [setNewsletterState])

  useEffect(() => {
    if (!newsletterState) {
      setSuccessState("notSent")
    }

    if (newsletterState) {
      ReactGA.modalview("newsletter-modal")
    }
  }, [newsletterState])

  // Form functions
  function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setFormResults({ ...formResults, [event.target.name]: event.target.value })
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": formName, ...formResults }),
    })
      .then(() => {
        setSuccessState("isLoading")

        setTimeout(() => {
          setSuccessState("isSent")
        }, 1500)

        ReactGA.event({
          category: "Form",
          action: "Submitted newsletter sign-up",
          label: "Newsletter sign-up",
        })
      })
      .catch(error => alert(error))

    event.preventDefault()
  }

  return (
    <AnimatePresence exitBeforeEnter>
      {newsletterState && (
        <motion.div
          initial='initial'
          animate='animate'
          exit='exit'
          variants={newsletterVars}
          className='c-newsletter'
          key='newsletter'
        >
          <form
            name={formName}
            method='POST'
            data-netlify='true'
            data-netlify-honeypot='bot-field'
            autoComplete='off'
            onSubmit={handleSubmit}
          >
            <div>
              <h5>Join our mailing list</h5>
            </div>

            <input type='hidden' name='form-name' value={formName} />
            <p hidden>
              <label id={`${formName}-bot-label`}>
                Don't fill this out if you're human: <input name='bot-field' aria-labelledby={`${formName}-bot-label`} />
              </label>
            </p>

            {successState !== "isLoading" && successState !== "isSent" && (
              <motion.div className='c-lrg-form__field' initial='initial' animate='animate' exit='exit' variants={fadeInOut}>
                <label className='c-lrg-form__label' htmlFor='newsletter_email'>
                  Enter your email*
                </label>
                <input
                  type='email'
                  id='newsletter_email'
                  name='Newsletter Email'
                  className='c-lrg-form__input'
                  required
                  onFocus={e => {
                    const arr: Array<HTMLElement> = Array.from(
                      e.currentTarget?.parentElement?.children as HTMLCollectionOf<HTMLElement>
                    )
                    const label = arr.find(elem => elem.tagName === "LABEL")

                    label?.classList.add("is-active")
                  }}
                  onBlur={e => {
                    if (!e.currentTarget.value) {
                      const arr: Array<HTMLElement> = Array.from(
                        e.currentTarget?.parentElement?.children as HTMLCollectionOf<HTMLElement>
                      )
                      const label = arr.find(elem => elem.tagName === "LABEL")

                      label?.classList.remove("is-active")
                    }
                  }}
                  onChange={handleChange}
                  onMouseEnter={() => setCursorState?.("is-link")}
                  onMouseLeave={() => setCursorState?.("")}
                />
              </motion.div>
            )}

            {successState === "isLoading" && (
              <motion.div initial='initial' animate='animate' exit='exit' variants={fadeInOut}>
                <Spinner />
              </motion.div>
            )}

            {successState === "isSent" && (
              <motion.p
                initial='initial'
                animate='animate'
                exit='exit'
                variants={fadeInOut}
                className='o-par-lrg'
                style={{ textAlign: "center" }}
              >
                You have successfully subscribed
              </motion.p>
            )}

            <div className='c-newsletter__actions'>
              <button
                className='o-circ-btn'
                type='submit'
                onMouseEnter={() => setCursorState?.("is-link")}
                onMouseLeave={() => setCursorState?.("")}
              >
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 113.39 113.39'>
                  <path
                    d='M104.5,58.68,72.89,83.5l2.7,3,37.8-29.93L75.59,26.84l-2.71,3L104.5,54.7H0v4Z'
                    fill='rgba(250, 250, 250, 1)'
                    fillRule='evenodd'
                  />
                  <rect width='113.39' height='113.39' fill='none' opacity='0' />
                </svg>
              </button>
              <button
                className='o-circ-btn'
                type='button'
                onMouseEnter={() => setCursorState?.("is-link")}
                onMouseLeave={() => setCursorState?.("")}
                onClick={() => setNewsletterState?.(!newsletterState)}
              >
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 113.39 113.39'>
                  <path
                    d='M32.31,34l3.33-3.33L58.35,53.27,81.07,30.65,84.39,34,61.68,56.69,84.39,79.41l-3.33,3.32L58.35,60.12,35.64,82.73l-3.33-3.32L55,56.69Z'
                    fill='rgba(250, 250, 250, 1)'
                  />
                  <rect width='113.39' height='113.39' fill='none' opacity='0' />
                </svg>
              </button>
            </div>
          </form>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Newsletter
