import * as React from "react"
import { useContext } from "react"
import ReactGA from "react-ga"

// Context
import NewsletterContext from "../../context/newsletterContext"
import CursorContext from "../../context/cursorContext"
import SapContext from "../../context/sapContext"

// Styles
import "./_footer.scss"

// Components
import { Link, useStaticQuery, graphql } from "gatsby"
import { FaPaperPlane, FaDribbble, FaInstagram } from "react-icons/fa"
import { FlexRow } from "../StyledComponents/layoutComponentStyles"
import { $desktop, $tablet } from "../../utils/breakpoints"

/*









*/

const Footer: React.FunctionComponent = () => {
  const siteData = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        siteAuthor
        siteDescription
        siteTitle
        workAvailability
      }
    }
  `)

  const data = siteData.sanitySiteSettings

  // Context
  const { setSapState } = useContext(SapContext)
  const { setCursorState } = useContext(CursorContext)
  const { newsletterState, setNewsletterState } = useContext(NewsletterContext)

  return (
    <footer className='o-footer'>
      <div className='c-footer__cta'>
        <h1>Ready to get going?</h1>
        <button
          className='c-footer-cta__link'
          onClick={() => {
            setSapState?.("start")
            ReactGA.event({ category: "Navigation", action: "Clicked footer SAP button", label: "Footer SAP Button" })
          }}
          onMouseEnter={() => setCursorState?.("is-link")}
          onMouseLeave={() => setCursorState?.("")}
        >
          <h1>Start a project</h1>
          <svg width='67' height='67' viewBox='0 0 67 67' fill='transparent' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M24.6384 56.6703L38.7143 42.536L40.4129 40.8303H38.0058H1V26.0803H38.0058H40.4125L38.7144 24.3748L24.7277 10.3269L33.6865 1.41394L65.5889 33.4554L33.5999 65.586L24.6384 56.6703Z'
              stroke='rgba(22, 22, 22, 1)'
              strokeWidth='2'
            />
          </svg>
        </button>
      </div>

      <div className='c-footer__menu'>
        <div className='c-footer__contact-info'>
          <a
            href='mailto:hello@silkstudio.co'
            onMouseEnter={() => setCursorState?.("is-link")}
            onMouseLeave={() => setCursorState?.("")}
          >
            <p className='o-par-lrg'>
              <span>E:</span>
              <span> hello@silkstudio.co</span>
            </p>
          </a>
          {/* <a href='tel:+441743224262' onMouseEnter={() => setCursorState?.("is-link")} onMouseLeave={() => setCursorState?.("")}>
            <p className='o-par-lrg'>
              <span>T:</span>
              <span> +44 1743 224262</span>
            </p>
          </a> */}
        </div>
        <div className='c-footer__availability'>
          <p>{/* <span>Available for work from:</span> <span>{`${data.workAvailability}`}</span> */}</p>
        </div>
        <div className='c-footer__social'>
          <a
            href='https://www.dribbble.com/madebysilkstudio'
            onMouseEnter={() => setCursorState?.("is-link")}
            onMouseLeave={() => setCursorState?.("")}
          >
            {$desktop ? <p>dribbble</p> : $tablet ? <p>drbl</p> : <FaDribbble />}
          </a>
          <a
            href='https://www.instagram.com/madebysilkstudio'
            onMouseEnter={() => setCursorState?.("is-link")}
            onMouseLeave={() => setCursorState?.("")}
          >
            {$desktop ? <p>instagram</p> : $tablet ? <p>inst</p> : <FaInstagram />}
          </a>
          <button
            className='o-newsletter-btn'
            onClick={() => setNewsletterState?.(!newsletterState)}
            tabIndex={0}
            onKeyDown={() => null}
            onMouseEnter={() => setCursorState?.("is-link")}
            onMouseLeave={() => setCursorState?.("")}
          >
            {$desktop ? <p>newsletter</p> : $tablet ? <p>nslr</p> : <FaPaperPlane />}
          </button>
        </div>
      </div>
      <div className='c-footer__sub-menu'>
        <FlexRow justifySpaceBetween>
          <p className='o-par-sml'>Silk Studio © {new Date().getFullYear()}</p>
          <div className='c-footer-sub-menu__items'>
            {/* @ts-expect-error */}
            <Link to='/terms/' onMouseEnter={() => setCursorState?.("is-link")} onMouseLeave={() => setCursorState?.("")}>
              <p className='o-par-sml'>Terms</p>
            </Link>
            {/* @ts-expect-error */}
            <Link to='/sitemap.xml' onMouseEnter={() => setCursorState?.("is-link")} onMouseLeave={() => setCursorState?.("")}>
              <p className='o-par-sml'>Sitemap</p>
            </Link>
          </div>
        </FlexRow>
      </div>
    </footer>
  )
}

export default Footer
