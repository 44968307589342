import styled from "styled-components"
import { motion } from "framer-motion"

export const Sap = styled(motion.div)`
  visibilty: visible;
`

export const SapContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 995;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: opacity 0.3s linear;
  height: 100vh;
  min-height: 60rem;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1024px) {
    height: ${window.innerHeight}px;
  }
`

export const SapInner = styled(motion.div)`
  width: 68.375rem;
  z-index: 998;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  .o-type-xl {
    @media screen and (max-width: 480px) {
      font-size: 12.375rem;
      text-align: center;
    }
  }
`

export const SapButtons = styled(motion.div)`
  position: relative;
  width: 39rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3.5rem;

  @media screen and (max-width: 1024px) {
    margin-top: 5rem;
    width: 60rem;
  }

  @media screen and (max-width: 480px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 5rem;
    margin: 7.5rem auto 0 auto;
  }
`

export const SapFormContainer = styled(motion.div)`
  width: 100vw;
  height: 100%;
  height: ${window.innerHeight}px;
  position: relative;
`

export const SapFormLabel = styled.label`
  position: absolute;
  left: 2rem;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  color: white;
  opacity: 0.5;
  transition: all 0.9s cubic-bezier(0.16, 1, 0.3, 1);
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    left: 3rem;
    font-size: 2.05rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 4.3653rem;
    left: 5rem;
  }
`

export const MultiInputRow = styled(motion.div)`
  width: 39rem;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  background-color: transparent;

  p,
  o-par-lrg {
    opacity: 1 !important;

    @media screen and (max-width: 480px) {
      opacity: 0.5;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    margin: 12.5rem auto 0 auto;
  }
`

export const SapSiteContactDetails = styled(motion.div)`
  width: 26.5rem;
  position: fixed;
  bottom: 1.5rem;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.9s cubic-bezier(0.16, 1, 0.3, 1);
  mix-blend-mode: difference;

  @media screen and (max-width: 1024px) {
    width: 42.5rem;
    bottom: 3.5vw;
  }

  @media screen and (max-width: 480px) {
    width: 80.0324rem;
    justify-content: space-evenly;
    flex-direction: column;
    bottom: 5.75rem;
    opacity: 0;
    visibilty: hidden;
    display: none;
  }

  p {
    span:first-child {
      font-weight: 700;
      transition: all 0.9s cubic-bezier(0.16, 1, 0.3, 1);
      opacity: 0.25;
    }

    span:last-child {
      opacity: 0.3;
      transition: all 0.9s cubic-bezier(0.16, 1, 0.3, 1);
      &:hover {
        opacity: 1;
      }
    }
  }
`

export const SapUserContactInfo = styled.div`
  margin: 3.5rem auto 2rem auto;
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 50%) minmax(0, 50%);
  grid-template-rows: minmax(0, 4.185rem) minmax(0, 4.185rem) minmax(0, 4.185rem) minmax(0, 50%) minmax(0, auto);
  grid-template-areas:
    "a b"
    "c d"
    "e f"
    "g g"
    "h h";

  gap: 2rem;
  row-gap: 2.5rem;
  justify-items: center;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    margin: 4.5rem auto 4rem auto;
    grid-template-rows: minmax(0, 8rem) minmax(0, 8rem) minmax(0, 8rem) minmax(0, 50%);
    gap: 2rem;
    row-gap: 3.5rem;
  }

  @media screen and (max-width: 480px) {
    margin: 5rem auto 7.5rem auto;

    grid-template-areas:
      "a"
      "b"
      "c"
      "d"
      "e"
      "f"
      "g"
      "h";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr minmax(0, auto) 0.75fr;
    gap: 7rem;
  }

  & > div:nth-of-type(1) {
    grid-area: a;
  }

  & > div:nth-of-type(2) {
    grid-area: b;
  }

  & > div:nth-of-type(3) {
    grid-area: c;
  }

  & > div:nth-of-type(4) {
    grid-area: d;
  }

  & > div:nth-of-type(5) {
    grid-area: e;
  }

  & > div:nth-of-type(6) {
    grid-area: f;
  }

  & > div:nth-of-type(7) {
    grid-area: g;
  }

  & > div:nth-of-type(8) {
    grid-area: h;
  }
`
