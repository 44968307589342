import * as React from "react"
import { useEffect, useRef } from "react"

// Components
import { SapPillButton } from "../../Buttons/pillButton"

// Animations
import { stepTitleAnimDelayed, sapBtnAnim, fieldVars } from "../../../animations/pages/sapAnim"
import { createRevealTitle } from "../../../animations/globalAnims"
import { AnimatePresence, motion } from "framer-motion"

// Interfaces
import { SapFormFieldProps } from "../StartAProject"

/*









*/

const ScopeField: React.FunctionComponent<SapFormFieldProps> = ({ step, stepForward, positionInForm, handleChange, SapFormType }) => {
  // Refs
  const subTitleRef = useRef<HTMLParagraphElement>(null)
  const titleRef = useRef<HTMLHeadingElement>(null)

  // Effects
  useEffect(() => {
    const btns: Array<HTMLElement> = Array.from(document.querySelectorAll(".pill-btn.step-1"))
    if (step === positionInForm) {
      createRevealTitle(subTitleRef.current)
      stepTitleAnimDelayed(titleRef.current)
      sapBtnAnim(btns)
    }
  }, [step])

  return (
    <AnimatePresence exitBeforeEnter>
      {step === positionInForm && (
        <motion.div className='o-form-step' initial='initial' animate='animate' exit='exit' variants={fieldVars}>
          <p className='o-par-lrg' ref={subTitleRef}>
            Let's take the first step towards the <i>new</i> you...
          </p>
          <h2 ref={titleRef}>What does the project involve?</h2>
          <fieldset id={`1. ${SapFormType} Scope`}>
            <div className='o-five-btn-grid'>
              <SapPillButton
                step={1}
                stepForward={stepForward}
                id={"complete-website"}
                value={"Complete website"}
                name={`1. ${SapFormType} Scope`}
                handleChange={handleChange}
              />
              <SapPillButton
                step={1}
                stepForward={stepForward}
                id={"ui-ux-design"}
                value={"UI/UX Design"}
                name={`1. ${SapFormType} Scope`}
                handleChange={handleChange}
              />
              <SapPillButton
                step={1}
                stepForward={stepForward}
                id={"branding"}
                value={"Branding"}
                name={`1. ${SapFormType} Scope`}
                handleChange={handleChange}
              />
              <SapPillButton
                step={1}
                stepForward={stepForward}
                id={"development"}
                value={"Development"}
                name={`1. ${SapFormType} Scope`}
                handleChange={handleChange}
              />
              <SapPillButton
                step={1}
                stepForward={stepForward}
                id={"brand-and-website"}
                value={"Brand and Website"}
                name={`1. ${SapFormType} Scope`}
                handleChange={handleChange}
              />
            </div>
          </fieldset>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default ScopeField
