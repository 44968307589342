import * as React from "react"
import { useState, useEffect } from "react"
import { debounce } from "ts-debounce"
/* eslint-disable-next-line */
import { useLocation } from "@reach/router"

/*









*/

const BodySizeHandler: React.FunctionComponent = () => {
  const location = useLocation()

  /**
   * Store the current dimensions in a state
   */

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })

  useEffect(() => {
    /**
     * Create the resize function
     */

    const handleResize = (): void => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
      // console.log("debouced handle resize ran")
    }

    /**
     * Create debounce handler for the function that runs immeaditalely and then on resize after 1000ms
     */

    const debouncedHandleResize = debounce(handleResize, 1000, { isImmediate: true })

    /**
     * Add event listener to run the denounced function on resize
     */
    window.addEventListener("resize", debouncedHandleResize)

    /**
     * Remove event listener on unmount
     */
    return () => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  }, [])

  useEffect(() => {
    /**
     *Define the scroll container in a variable
     */
    const scrollContainer = document.getElementById("scroll-container")

    /**
     * Create a timeout function to set reset the body size if callbacks change
     */
    if (scrollContainer) {
      setTimeout(() => {
        document.body.style.height = `${scrollContainer.clientHeight + dimensions.height}px`
        // console.log("timeout body size handler ran")
      }, 2000)
    }
  }, [dimensions.height, location])

  return null
}

export default BodySizeHandler
