import * as React from "react"
import { useRef, useEffect, useContext } from "react"
import ReactGA from "react-ga"

// Components
import { Section, Container } from "../StyledComponents/layoutComponentStyles"
import { SapInner } from "../StyledComponents/sapStyledComponents"
import { ActionPillButton } from "../Buttons/pillButton"
import { SapButtons } from "../StyledComponents/sapStyledComponents"
import SapContext from "../../context/sapContext"

// Animation
import { sapHeroTitleAnim, sapSectionExitAnim } from "../../animations/pages/sapAnim"
import { motion } from "framer-motion"
import { SplitText } from "gsap/SplitText"
import { btnParentVars, sapBtnChildVars } from "../../animations/pages/sapAnim"

/*








*/

interface SapLandingProps {}

const SapLanding: React.FunctionComponent<SapLandingProps> = () => {
  // Context
  const { setSapState } = useContext(SapContext)

  // Refs
  const titleRef = useRef<HTMLHeadingElement>(null)

  // Effects
  useEffect(() => {
    if (document.fonts) {
      document.fonts.ready.then(() => {
        const splitInner = new SplitText(titleRef.current, {
          type: "lines",
          linesClass: "sap-title__inner",
        })
        new SplitText(titleRef.current, {
          type: "lines",
          linesClass: "u-overflow-reveal",
        })
        sapHeroTitleAnim(splitInner.lines)
      })
    } else {
      setTimeout(() => {
        const splitInner = new SplitText(titleRef.current, {
          type: "lines",
          linesClass: "sap-title__inner",
        })
        new SplitText(titleRef.current, {
          type: "lines",
          linesClass: "u-overflow-reveal",
        })
        sapHeroTitleAnim(splitInner.lines)
      }, 500)
    }
  }, [])

  useEffect(() => {
    ReactGA.modalview("sap-modal")
  }, [])

  return (
    <Section fullHeight alignCenter>
      <Container fourteenClm>
        <SapInner>
          <h1 className='o-type-xl' ref={titleRef}>
            Let's get this show on the road
          </h1>
          <SapButtons initial='initial' animate='animate' exit='exit' variants={btnParentVars} key='btns-parent'>
            <motion.div key='call-btn' variants={sapBtnChildVars} onClick={() => sapSectionExitAnim()}>
              <ActionPillButton text='Schedule a call' clickAction={() => setSapState?.("call")} />
            </motion.div>
            <motion.div key='request-btn' variants={sapBtnChildVars} onClick={() => sapSectionExitAnim()}>
              <ActionPillButton text='Request a proposal' clickAction={() => setSapState?.("proposal")} />
            </motion.div>
          </SapButtons>
        </SapInner>
      </Container>
    </Section>
  )
}

export default SapLanding
