import * as React from "react"

import { useRef, useContext } from "react"
import CursorContext from "../../context/cursorContext"
import SapContext from "../../context/sapContext"

// Components
import { RiPencilFill } from "react-icons/ri"
import { MdClose } from "react-icons/md"
import { Sap } from "../StyledComponents/sapStyledComponents"

// Animations
import { AnimatePresence, motion } from "framer-motion"
import SapButtonSafeContext from "../../context/sapButtonSafeContext"

// Variants
const sapFadeInOut = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 0.225, ease: "easeOut" } },
  exit: { opacity: 0, transition: { duration: 0.225, ease: "easeOut" } },
}

interface SapButtonProps {
  landingExitAnim: Function
}

/*









*/

const SAPButton: React.FunctionComponent<SapButtonProps> = ({ landingExitAnim }) => {
  // Context
  const { setCursorState } = useContext(CursorContext)
  const { sapState, setSapState } = useContext(SapContext)
  const { sapButtonSafe } = useContext(SapButtonSafeContext)

  // Refs
  const btnRef = useRef<HTMLButtonElement>(null)
  const bgRef = useRef<HTMLDivElement>(null)

  return (
    <AnimatePresence initial={false}>
      {sapButtonSafe && (
        <Sap initial='initial' animate='animate' exit='exit' variants={sapFadeInOut}>
          <button
            ref={btnRef}
            className={`c-project-btn${sapState !== "closed" ? " is-active" : ""}`}
            onClick={() => {
              if (sapState === "closed") {
                setSapState?.("start")
              } else if (sapState === "start") {
                landingExitAnim()
              } else {
                setSapState?.("closed")
              }
            }}
            onMouseEnter={() => setCursorState?.("is-sap")}
            onMouseLeave={() => setCursorState?.("")}
          >
            <AnimatePresence initial={false} exitBeforeEnter>
              {sapState === "closed" ? <SapOpenIcon key={"open"} /> : <SapCloseIcon key={"close"} />}
            </AnimatePresence>
          </button>

          <div className='c-project-btn__background' ref={bgRef}></div>
        </Sap>
      )}
    </AnimatePresence>
  )
}

export default SAPButton

export const SapOpenIcon = () => (
  <motion.div initial='initial' animate='animate' exit='exit' variants={sapFadeInOut} key='icon-open' className='c-project-btn__icon'>
    <RiPencilFill />
  </motion.div>
)

export const SapCloseIcon = () => (
  <motion.div initial='initial' animate='animate' exit='exit' variants={sapFadeInOut} key='icon-close' className='c-project-btn__icon'>
    <MdClose />
  </motion.div>
)
