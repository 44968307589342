import React from "react"

interface NavContextProps {
  navState?: boolean
  setNavState?: React.Dispatch<React.SetStateAction<boolean>>
}

const NavContext = React.createContext<NavContextProps>({})

export const NavProvider = NavContext.Provider

export default NavContext
