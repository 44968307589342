import styled, { css } from "styled-components"
import { motion } from "framer-motion"

// Interfaces
interface SectionProps {
  readonly fullHeight?: boolean
  readonly alignCenter?: boolean
  readonly spaced?: boolean
}

interface ContainerProps {
  readonly sixteenClm?: boolean
  readonly fourteenClm?: boolean
  readonly twelveClm?: boolean
  readonly tenClm?: boolean
  readonly eightClm?: boolean
  readonly sixClm?: boolean
  readonly fullHeight?: boolean
  readonly project?: boolean
}

interface FlexRowProps {
  readonly justifyStart?: boolean
  readonly justifyEnd?: boolean
  readonly justifySpaceBetween?: boolean
  readonly justifySpaceAround?: boolean
  readonly alignStart?: boolean
  readonly alignEnd?: boolean
  readonly alignBaseline?: boolean
  readonly alignStretch?: boolean
  readonly mobileDirColumn?: boolean
  readonly mobileDirColumnReverse?: boolean
  readonly mobileJustifySpaceBetween?: boolean
  readonly mobileJustifySpaceAround?: boolean
  readonly mobileAlignStart?: boolean
  readonly mobileJustifyStart?: boolean
  readonly mobileAlignEnd?: boolean
  readonly mobileJustifyEnd?: boolean
}

interface JournalGalleryItemProps {
  readonly featuredImage?: string
}

// Export components
export const AppContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  @media screen and (max-width: 1024px) {
    position: relative;
    height: 100%;
    width: 100%;
  }
`

export const ScrollContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: ${props => props.theme.backgroundColor};
`

export const Section = styled(motion.section)<SectionProps>`
  background-color: transparent;
  position: relative;
  ${props =>
    props.fullHeight &&
    css`
      @media screen and (min-width: 1025px) {
        height: 100vh;
        min-height: 60rem;
      }
      @media screen and (max-width: 1024px) {
        height: ${typeof window !== `undefined` ? window.innerHeight + "px" : "100vh"};
      }
    `}
  ${props =>
    props.alignCenter &&
    css`
      display: flex;
      align-items: center;
    `};
  ${props =>
    props.spaced &&
    css`
      margin: 15rem 0;

      @media screen and (max-width: 1024px) {
        margin: 18.75rem 0;
      }
      @media screen and (max-width: 480px) {
        margin: 30rem 0;
      }
    `}
`

export const Container = styled(motion.div)<ContainerProps>`
  position: relative;
  margin: 0 auto;
  transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
    ${props =>
      props.sixteenClm &&
      css`
        width: 100rem;

        @media screen and (max-width: 1024px) {
          width: 104.1849rem;
        }
        @media screen and (max-width: 480px) {
          width: 104.1849rem;
        }
      `}
    ${props =>
      props.fourteenClm &&
      css`
        width: 87.25rem;
        @media screen and (max-width: 1024px) {
          width: 93.9008rem;
        }
        @media screen and (max-width: 480px) {
          width: 104.1849rem;
        }
      `}
    ${props =>
      props.twelveClm &&
      css`
        width: 74.625rem;
        @media screen and (max-width: 1024px) {
          width: 80.0324rem;
        }
      `}
    ${props =>
      props.tenClm &&
      css`
        width: 61.875rem;

        @media screen and (max-width: 1024px) {
          width: 66.4529rem;
        }

        @media screen and (max-width: 480px) {
          width: 104.1849rem;
        }
      `}
    ${props =>
      props.eightClm &&
      css`
        width: 49.25rem;
        @media screen and (max-width: 1024px) {
          width: 52.5844rem;
        }
      `}
    ${props =>
      props.sixClm &&
      css`
        width: 39rem;
        @media screen and (max-width: 1024px) {
          width: 39.0049rem;
        }
      `}
    ${props =>
      props.fullHeight &&
      css`
        display: flex;
        align-items: center;
        height: 100%;
      `}
      ${props =>
        props.project &&
        css`
          height: 100vh;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, 0);
          z-index: 2;
          mix-blend-mode: difference;

          .o-vertical-tagline {
            opacity: 1;
          }
        `}
`

export const FlexRow = styled(motion.div)<FlexRowProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.justifyStart &&
    css`
      justify-content: flex-start;
    `}

  ${props =>
    props.justifyEnd &&
    css`
      justify-content: flex-end;
    `}

  ${props =>
    props.justifySpaceBetween &&
    css`
      justify-content: space-between;
    `}

  ${props =>
    props.justifySpaceAround &&
    css`
      justify-content: space-around;
    `}

  ${props =>
    props.alignStart &&
    css`
      align-items: flex-start;
    `}

  ${props =>
    props.alignEnd &&
    css`
      align-items: flex-end;
    `}
  
  ${props =>
    props.alignBaseline &&
    css`
      align-items: baseline;
    `}

  ${props =>
    props.alignStretch &&
    css`
      align-items: stretch;
    `}
      
  ${props =>
    props.mobileDirColumn &&
    css`
      @media screen and (max-width: 480px) {
        flex-direction: column;
      }
    `}

  ${props =>
    props.mobileDirColumnReverse &&
    css`
      @media screen and (max-width: 480px) {
        flex-direction: column-reverse;
      }
    `}

  ${props =>
    props.mobileJustifyStart &&
    css`
      @media screen and (max-width: 480px) {
        justify-content: flex-start;
      }
    `}
    
  ${props =>
    props.mobileJustifyEnd &&
    css`
      @media screen and (max-width: 480px) {
        justify-content: flex-end;
      }
    `}

  ${props =>
    props.mobileJustifySpaceBetween &&
    css`
      @media screen and (max-width: 480px) {
        justify-content: space-between;
      }
    `}
    
  ${props =>
    props.mobileJustifySpaceAround &&
    css`
      @media screen and (max-width: 480px) {
        justify-content: space-around;
      }
    `}

  ${props =>
    props.mobileAlignStart &&
    css`
      @media screen and (max-width: 480px) {
        align-items: flex-start;
      }
    `}

  ${props =>
    props.mobileAlignEnd &&
    css`
      @media screen and (max-width: 480px) {
        align-items: flex-end;
      }
    `}
        
`

export const JournalGalleryItems = styled.div`
  margin: 6.5rem 0;
  border-bottom: solid 1px ${props => props.theme.textColor};
  @media screen and (max-width: 1024px) {
    margin: 10rem 0;
  }
  @media screen and (max-width: 480px) {
    margin: 12rem 0;
  }
`

export const JournalGalleryItem = styled(motion.div)<JournalGalleryItemProps>`
  height: 12rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border-top: solid 1px ${props => props.theme.textColor};
  padding: 0 3rem;
  transition: all 0.9s cubic-bezier(0.16, 1, 0.3, 1);
  @media screen and (max-width: 1024px) {
    height: 25rem;
  }
  @media screen and (max-width: 480px) {
    height: 50rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; 
  }
  &::before {
    content: "";
    background-image: url("${props => props.featuredImage}");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0;
    transition: all 0.9s cubic-bezier(0.16, 1, 0.3, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  &:hover  {
    &::before {
      opacity: .75;
    }
  }

  h5 {
    font-weight: 500;
  }

  h5,
  p,
  .o-par-sml {
    color: ${props => props.theme.textColor};
    mix-blend-mode: normal;
  }

  h5, p, .o-par-sml, svg, div { 
    z-index: 2;
  }





`

export const PostFooterContainer = styled.div`
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: ${props => props.theme.textColor};
  margin: 15rem 0 0 0;
  @media screen and (max-width: 1024px) {
    margin: 15rem 0 5rem 0;
  }
  @media screen and (max-width: 480px) {
    margin: 22.5rem 0;
    h3 {
      margin-bottom: 10rem;
    }
  }
`
